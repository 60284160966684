<template>
    <v-container
        fluid
        class="pa-0 ma-0"
        >
        <v-row
            class="ma-0"
        >
            <v-btn-toggle
                    v-model="tab"
                    mandatory
                    group
                    class="summaryContainer justify-space-between"
                >

                <!-- <v-btn disabled value="Green Cover" class="tabBtn" @click="changeTab"> -->
                <v-btn disabled value="Green Cover" class="tabBtn">
                <!-- Green Cover &#38; Green Density --> Plant Health 
                </v-btn>
                <!-- <v-btn value="Top 10" class="tabBtn" @click="changeTab">Top 10 Change Results</v-btn> -->
            </v-btn-toggle>
        </v-row>
        <div
            class="mt-5"
        >
            <div v-if="tab === 'Green Cover'">

            <v-row justify="space-around" class="summaryContainer mt-10 mb-4">
                <v-col
                    cols="6"
                    class="pa-0 ma-0"
                >
                    <p class="pa-0 ma-0 ml-9 SummarySubHeading">Data for the month of <span class="greenCoverColor text-uppercase">{{month_label}}</span> {{globalYear}}</p>
                </v-col>
                <v-col
                    cols="6"
                    class="pa-0 ma-0"
                >
                
                    <p class="py-0 pl-6 ma-0 SummarySubHeading d-inline">Data for the month of</p>
                    <!-- <p class="d-inline">Item</p> -->
                         <MonthPicker
                            class="d-inline SelectionBox"
                            style="width: 100px;"
                            :localMonth = localMonth
                            @input="monthPicker"
                        />
                        <span>{{localYear}}</span>

                    <!-- <v-select
					    v-model="cmpmonth"
                        :items="months"
                        label="Select Date Period"
                        solo
                        class="pa-0 SelectionBox"
                        color= "#893FF2"
                    >
                    </v-select> -->
                    <!-- <v-autocomplete
                            v-model="cmpmonth"
                            :items="months"
                            dense
                            hide-selected
                            hide-detail
                            placeholder="Select Month "
                            @change="changeTo"
                            class="greenCoverColor"
                        >
                    </v-autocomplete> -->
                            <!-- {{cmpmonth}} -->

                             <!-- {{picker}} -->
                        <!-- <v-date-picker
                            v-model="picker"
                            type="month"
                            no-title
                            color="purple lighten-1"
                            @change="changeTo"
                        >
                        </v-date-picker> -->
                        
                    
                </v-col>
            </v-row>

            <!-- 2 Grid Panel -->
            <v-row class="pa-0 ma-0 summaryContainer flex-md-row flex-sm-column flex-column">
                <v-col
                class="pa-1 summaryBox panelBox">
                  <v-row class="greenCoverTM">
                        <v-col
                            class="pa-0"
                            cols="12"
                        >
                            <p class="ml-md-7 ml-sm-2 ml-2 greenCoverColor font-weight-black font-italic mb-0 greenCoverPanelText text-md-h5 text-sm-h6 text-h6">Plant Health <tooltip/> <span class="float-md-right font-weight-bold pr-md-7 pr-sm-3 pr-3 summarysqkm">sqkm&#178;</span></p>
                        </v-col>  
                    </v-row>
                    <!-- <v-btn @click="ttShow != ttShow">ToolTip</v-btn> -->
                    <v-row class="mt-10">
                        <v-col
                            class="pa-0"
                            cols="4"
                        >
                            <p class="panelSmallerCategory ml-md-7 ml-sm-2 ml-2 textStress">Stressed</p>
                        </v-col>
                        <v-col
                            class="pa-0 px-5 align-self-center"
                            cols="5"
                        >
                                <v-progress-linear
                                    :value="summary.PlantHealth.Left.stressed"
                                    background-color="white"
                                    color="bgStress"
                                    height="16"
                                    style="border: solid 1px #FF0000;"
                                >
                                    <strong class="textWhiteImp">{{ Math.ceil(summary.PlantHealth.Left.stressed) }}%</strong>
                                </v-progress-linear>
                        </v-col>
                        <v-col
                            cols="3"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM textStress">{{summary.PlantHealth.Left.stressed_sqkm.toFixed(2)}}</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="4"
                        >
                            <p class="panelSmallerCategory ml-md-7 ml-sm-2 ml-2 textSlightStress">Slightly Stressed</p>
                        </v-col>
                        <v-col
                            class="pa-0 px-5 align-self-center"
                            cols="5"
                        >
                            <v-progress-linear
                                :value="summary.PlantHealth.Left.slightstress"
                                background-color="white"
                                color="bgSlightStress"
                                height="16"
                                style="border: solid 1px #ED7D31;"
                            >
                                <strong class="textWhiteImp">{{ Math.ceil(summary.PlantHealth.Left.slightstress) }}%</strong>
                            </v-progress-linear>
                        </v-col>
                        <v-col
                            cols="3"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM textSlightStress">{{summary.PlantHealth.Left.slightstress_sqkm.toFixed(2)}}</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="4"
                        >
                            <p class="panelSmallerCategory ml-md-7 ml-sm-2 ml-2 textModerate">Moderate</p>
                        </v-col>
                        <v-col
                            class="pa-0 px-5 align-self-center"
                            cols="5"
                        >
                                <v-progress-linear
                                    :value="summary.PlantHealth.Left.Moderate"
                                    background-color="white"
                                    color="bgModerate"
                                    height="16"
                                    style="border: solid 1px #B8BB23;"
                                >
                                    <strong class="textWhiteImp">{{ Math.ceil(summary.PlantHealth.Left.Moderate) }}%</strong>
                                </v-progress-linear>
                        </v-col>
                        <v-col
                            cols="3"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM textSlightStress">{{summary.PlantHealth.Left.Moderate_sqkm.toFixed(2)}}</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                    <v-col
                        class="pa-0"
                        cols="4"
                    >
                        <p class="panelSmallerCategory ml-md-7 ml-sm-2 ml-2 textHealthy">Healthy</p>
                    </v-col>
                    <v-col
                        class="pa-0 px-5 align-self-center"
                        cols="5"
                    >
                            <v-progress-linear
                                :value="summary.PlantHealth.Left.Health"
                                background-color="white"
                                color="bgHealthy"
                                height="16"
                                style="border: solid 1px #649F25;"
                            >
                                <strong class="textWhiteImp">{{ Math.ceil(summary.PlantHealth.Left.Health) }}%</strong>
                            </v-progress-linear>
                    </v-col>
                    <v-col
                        cols="3"
                        class="pa-0"
                        align-self="end"
                    >
                        <p class="text-right panelSmallerCategory panelBoxRM textHealthy">{{summary.PlantHealth.Left.Health_sqkm.toFixed(2)}}</p>
                    </v-col>  
                </v-row>
                <v-row>
                    <v-col
                        class="pa-0"
                        cols="4"
                    >
                        <p class="panelSmallerCategory ml-md-7 ml-sm-2 ml-2 textVeryHealthy">Very Healthy</p>
                    </v-col>
                    <v-col
                        class="pa-0 px-5 align-self-center"
                        cols="5"
                    >
                            <v-progress-linear
                                :value="summary.PlantHealth.Left.VeryHealthy"
                                background-color="white"
                                color="bgVeryHealthy"
                                height="16"
                                style="border: solid 1px #649F25;"
                            >
                                <strong class="textWhiteImp">{{ Math.ceil(summary.PlantHealth.Left.VeryHealthy) }}%</strong>
                            </v-progress-linear>
                    </v-col>
                    <v-col
                        cols="3"
                        class="pa-0"
                        align-self="end"
                    >
                        <p class="text-right panelSmallerCategory panelBoxRM textVeryHealthy">{{summary.PlantHealth.Left.VeryHealthy_sqkm.toFixed(2)}}</p>
                    </v-col>  
                </v-row> 
                </v-col>
                <v-col
                class="pa-1 summaryBox panelBox">
                    <v-row class="greenCoverTM">
                        <v-col
                            class="pa-0"
                            cols="12"
                        >
                            <p class="ml-md-7 ml-sm-2 ml-2 greenCoverColor font-weight-black font-italic mb-0 greenCoverPanelText text-md-h5 text-sm-h6 text-h6">Plant Health <tooltip/> <span class="float-md-right font-weight-bold pr-md-7 pr-sm-3 pr-3 summarysqkm">sqkm&#178;</span></p>
                        </v-col>  
                    </v-row>

                    <v-row class="mt-10">
                        <v-col
                            class="pa-0"
                            cols="4"
                        >
                            <p class="panelSmallerCategory ml-md-7 ml-sm-2 ml-2 textStress">Stressed</p>
                        </v-col>
                        <v-col
                            class="pa-0 px-5 align-self-center"
                            cols="5"
                        >
                                <v-progress-linear
                                    :value="summary.PlantHealth.Right.stressed"
                                    background-color="white"
                                    color="bgStress"
                                    height="16"
                                    style="border: solid 1px #FF0000;"
                                >
                                    <strong class="textWhiteImp">{{ Math.ceil(summary.PlantHealth.Right.stressed) }}%</strong>
                                </v-progress-linear>
                        </v-col>
                        <v-col
                            cols="3"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM textStress">{{summary.PlantHealth.Right.stressed_sqkm.toFixed(2)}}</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="4"
                        >
                            <p class="panelSmallerCategory ml-md-7 ml-sm-2 ml-2 textSlightStress">Slightly Stressed</p>
                        </v-col>
                        <v-col
                            class="pa-0 px-5 align-self-center"
                            cols="5"
                        >
                            <v-progress-linear
                                :value="summary.PlantHealth.Right.slightstress"
                                background-color="white"
                                color="bgSlightStress"
                                height="16"
                                style="border: solid 1px #ED7D31;"
                            >
                                <strong class="textWhiteImp">{{ Math.ceil(summary.PlantHealth.Right.slightstress) }}%</strong>
                            </v-progress-linear>
                        </v-col>
                        <v-col
                            cols="3"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM textSlightStress">{{summary.PlantHealth.Right.slightstress_sqkm.toFixed(2)}}</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="4"
                        >
                            <p class="panelSmallerCategory ml-md-7 ml-sm-2 ml-2 textModerate">Moderate</p>
                        </v-col>
                        <v-col
                            class="pa-0 px-5 align-self-center"
                            cols="5"
                        >
                                <v-progress-linear
                                    :value="summary.PlantHealth.Right.Moderate"
                                    background-color="white"
                                    color="bgModerate"
                                    height="16"
                                    style="border: solid 1px #B8BB23;"
                                >
                                    <strong class="textWhiteImp">{{ Math.ceil(summary.PlantHealth.Right.Moderate) }}%</strong>
                                </v-progress-linear>
                        </v-col>
                        <v-col
                            cols="3"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM textSlightStress">{{summary.PlantHealth.Right.Moderate_sqkm.toFixed(2)}}</p>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="4"
                        >
                            <p class="panelSmallerCategory ml-md-7 ml-sm-2 ml-2 textHealthy">Healthy</p>
                        </v-col>
                        <v-col
                            class="pa-0 px-5 align-self-center"
                            cols="5"
                        >
                                <v-progress-linear
                                    :value="summary.PlantHealth.Right.Health"
                                    background-color="white"
                                    color="bgHealthy"
                                    height="16"
                                    style="border: solid 1px #649F25;"
                                >
                                    <strong class="textWhiteImp">{{ Math.ceil(summary.PlantHealth.Right.Health) }}%</strong>
                                </v-progress-linear>
                        </v-col>
                        <v-col
                            cols="3"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM textHealthy">{{summary.PlantHealth.Right.Health_sqkm.toFixed(2)}}</p>
                        </v-col>  
                    </v-row>
                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="4"
                        >
                            <p class="panelSmallerCategory ml-md-7 ml-sm-2 ml-2 textVeryHealthy">Very Healthy</p>
                        </v-col>
                        <v-col
                            class="pa-0 px-5 align-self-center"
                            cols="5"
                        >
                                <v-progress-linear
                                    :value="summary.PlantHealth.Right.VeryHealthy"
                                    background-color="white"
                                    color="bgVeryHealthy"
                                    height="16"
                                    style="border: solid 1px #649F25;"
                                >
                                    <strong class="textWhiteImp">{{ Math.ceil(summary.PlantHealth.Right.VeryHealthy) }}%</strong>
                                </v-progress-linear>
                        </v-col>
                        <v-col
                            cols="3"
                            class="pa-0"
                            align-self="end"
                        >
                            <p class="text-right panelSmallerCategory panelBoxRM textVeryHealthy">{{summary.PlantHealth.Right.VeryHealthy_sqkm.toFixed(2)}}</p>
                        </v-col>
                    </v-row>
                </v-col>
                </v-row>

            <p class="ml-6 mt-2 SummarySubHeading">Compared changes between 
                <span class="greenCoverColor">{{month_label}}</span>
                &#38;
                <span class="greenCoverColor">
                    {{compareMonth.toUpperCase()}}
                <!-- {{cmpmonth.toUpperCase()}} -->
            </span>  
            </p>

            <!-- <p>{{summary}}</p> -->
            <v-card 
            height="355px"
            class="summaryBox pa-2 topSectionBox mx-2 mt-3">
                <v-layout>
                    <v-col 
                    cols="12"
                    class="ma-0 pa-0">
                        <v-row
                        class="my-3"
                        >
                            <v-col
                            cols="3"
                            class="ma-0 pa-0"
                            >
                            <p class="ma-0 pa-0 text-center text-body-2">Area Changes in<br>
                                <span class="text-uppercase text-md-h6 textStress lh1-2Rem">Stressed</span>
                            </p>
                            </v-col>
                            
                            <v-col
                            cols="3"
                            class="ma-0 pa-0 justify-space-around alignColumn"
                            >
                                <p class="ma-0 pa-0 rowOneIndicator">{{Math.round(summary.changes.stressed)}}%
                                   
                                </p>

                                 <v-icon 
                                        v-if="summary.changes.stressed < 0"
                                        
                                        color= "#860030"
                                        class="align-self-start mdi-rotate-180 mdi-36px"
                                    >mdi-triangle
                                    </v-icon>
                                    <v-icon 
                                        v-else
                                        
                                        color= "#4DA76B"
                                        class="align-self-start mdi-36px"
                                    >mdi-triangle
                                    </v-icon>
                            </v-col>
                            <v-col
                                cols="5"
                                class="ma-0 pa-0"
                            >
                                <p class="ma-0 pa-0 topSummaryRightPanel ml-15">
                                    <span class="font-weight-bold">{{summary.changes.stressed_sqkm}} sqkm </span>
                                        <v-icon
                                            v-if="summary.changes.stressed < 0" 
                                            style="vertical-align: top;"
                                            small
                                            color= "#860030"
                                            class="mdi-rotate-180 raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            style="vertical-align: top;"
                                            small
                                            color= "#4DA76B"
                                            class="raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                    in <span class="textStress">Stressed</span><br>
                                    compared to <span class="RightTopSummaryDate greenCoverColor">
                                        {{compareMonth.toUpperCase()}}
                                        </span> records
                                </p>
                            </v-col>
                        </v-row>

                        <v-row
                        class="my-3"
                        >
                            <v-col
                            cols="3"
                            class="ma-0 pa-0"
                            >
                            <p class="ma-0 pa-0 text-center text-body2">Area Changes in<br>
                                <span class="text-uppercase text-md-h6 lh1-2Rem textSlightStress">Slightly Stress</span>
                            </p>
                            </v-col>
                            
                            <v-col
                            cols="3"
                            class="ma-0 pa-0 justify-space-around alignColumn"
                            >
                                <p class="ma-0 pa-0 rowOneIndicator">{{Math.round(summary.changes.slightstress)}}%
                                </p>
                                <v-icon 
                                        v-if="summary.changes.slightstress < 0"
                                        
                                        color= "#860030"
                                        class="align-self-start mdi-rotate-180 mdi-36px"
                                    >mdi-triangle
                                    </v-icon>
                                    <v-icon 
                                        v-else
                                        
                                        color= "#4DA76B"
                                        class="align-self-start mdi-36px"
                                    >mdi-triangle
                                </v-icon>
                            </v-col>
                            <v-col
                                cols="5"
                                class="ma-0 pa-0"
                            >
                                <p class="ma-0 pa-0 topSummaryRightPanel ml-15">
                            
                                    <span class="font-weight-bold">{{summary.changes.slightstress_sqkm}} sqkm </span>
                                        <v-icon
                                            v-if="summary.changes.slightstress < 0" 
                                            style="vertical-align: top;"
                                            small
                                            color= "#860030"
                                            class="mdi-rotate-180 raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            style="vertical-align: top;"
                                            small
                                            color= "#4DA76B"
                                            class="raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                    
                                    in <span class="textSlightStress">Slightly Stress</span><br>
                                    compared to <span class="RightTopSummaryDate greenCoverColor">{{compareMonth.toUpperCase()}}</span> records
                                </p>
                            </v-col>
                        </v-row>

                        <v-row
                        class="my-3"
                        >
                            <v-col
                            cols="3"
                            class="ma-0 pa-0"
                            >
                            <p class="ma-0 pa-0 text-center text-body2">Area Changes in<br>
                                <span class="text-uppercase text-md-h6 lh1-2Rem textModerate">Moderate</span>
                            </p>
                            </v-col>
                            
                            <v-col
                            cols="3"
                            class="ma-0 pa-0 justify-space-around alignColumn"
                            >
                                <p class="ma-0 pa-0 rowOneIndicator">{{Math.round(summary.changes.Moderate)}}%
                                    
                                </p>
                                <v-icon 
                                        v-if="summary.changes.Moderate < 0"
                                        
                                        color= "#860030"
                                        class="align-self-start mdi-rotate-180 mdi-36px"
                                    >mdi-triangle
                                    </v-icon>
                                    <v-icon 
                                        v-else
                                        
                                        color= "#4DA76B"
                                        class="align-self-start mdi-36px"
                                    >mdi-triangle
                                    </v-icon>
                            </v-col>
                            <v-col
                                cols="5"
                                class="ma-0 pa-0"
                            >
                                    <p class="ma-0 pa-0 topSummaryRightPanel ml-15">
                            
                                    <span class="font-weight-bold">{{summary.changes.Moderate_sqkm}} sqkm </span>
                                        <v-icon
                                            v-if="summary.changes.Moderate < 0" 
                                            style="vertical-align: top;"
                                            small
                                            color= "#860030"
                                            class="mdi-rotate-180 raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            style="vertical-align: top;"
                                            small
                                            color= "#4DA76B"
                                            class="raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                    
                                    in <span class="textModerate">Moderate</span><br>
                                    compared to <span class="RightTopSummaryDate greenCoverColor">{{compareMonth.toUpperCase()}}</span> records
                                </p>
                            </v-col>
                        </v-row>
                        <v-row
                        class="my-3"
                        >
                            <v-col
                            cols="3"
                            class="ma-0 pa-0"
                            >
                            <p class="ma-0 pa-0 text-center text-body2">Area Changes in<br>
                                <span class="text-uppercase text-md-h6 lh1-2Rem textHealthy">Healthy</span>
                            </p>
                            </v-col>
                            
                            <v-col
                            cols="3"
                            class="ma-0 pa-0 justify-space-around alignColumn"
                            >
                                <p class="ma-0 pa-0 rowOneIndicator">{{Math.round(summary.changes.Health)}}%
                                    
                                </p>
                                <v-icon 
                                        v-if="summary.changes.Health < 0"
                                        
                                        color= "#860030"
                                        class="align-self-start mdi-rotate-180 mdi-36px"
                                    >mdi-triangle
                                    </v-icon>
                                    <v-icon 
                                        v-else
                                        color= "#4DA76B"
                                        class="align-self-start mdi-36px"
                                    >mdi-triangle
                                    </v-icon>
                            </v-col>
                            <v-col
                                cols="5"
                                class="ma-0 pa-0"
                            >
                                <p class="ma-0 pa-0 topSummaryRightPanel ml-15">
                            
                                    <span class="font-weight-bold">{{summary.changes.Health_sqkm}} sqkm </span>
                                        <v-icon
                                            v-if="summary.changes.Health < 0" 
                                            style="vertical-align: top;"
                                            small
                                            color= "#860030"
                                            class="mdi-rotate-180 raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            style="vertical-align: top;"
                                            small
                                            color= "#4DA76B"
                                            class="raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                    
                                    in <span class="textHealthy">Healthy</span><br>
                                    compared to <span class="RightTopSummaryDate greenCoverColor">{{compareMonth.toUpperCase()}}</span> records
                                </p>
                            </v-col>
                        </v-row>
                        <v-row
                        class="my-3"
                        >
                            <v-col
                            cols="3"
                            class="ma-0 pa-0"
                            >
                            <p class="ma-0 pa-0 text-center text-body2">Area Changes in<br>
                                <span class="text-uppercase text-md-h6 lh1-2Rem textVeryHealthy">Very Healthy</span>
                            </p>
                            </v-col>
                            
                            <v-col
                            cols="3"
                            class="ma-0 pa-0 justify-space-around alignColumn"
                            >
                                <p class="ma-0 pa-0 rowOneIndicator">{{Math.round(summary.changes.VeryHealthy)}}%
                                    
                                </p>
                                <v-icon 
                                        v-if="summary.changes.VeryHealthy < 0"
                                        
                                        color= "#860030"
                                        class="align-self-start mdi-rotate-180 mdi-36px"
                                    >mdi-triangle
                                    </v-icon>
                                    <v-icon 
                                        v-else
                                        
                                        color= "#4DA76B"
                                        class="align-self-start mdi-36px"
                                    >mdi-triangle
                                    </v-icon>
                            </v-col>
                            <v-col
                                cols="5"
                                class="ma-0 pa-0"
                            >  
                                <p class="ma-0 pa-0 topSummaryRightPanel ml-15">
                            
                                    <span class="font-weight-bold">{{summary.changes.VeryHealthy_sqkm}} sqkm </span>
                                        <v-icon
                                            v-if="summary.changes.VeryHealthy < 0" 
                                            style="vertical-align: top;"
                                            small
                                            color= "#860030"
                                            class="mdi-rotate-180 raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            style="vertical-align: top;"
                                            small
                                            color= "#4DA76B"
                                            class="raiseTriangle"
                                            >mdi-triangle
                                        </v-icon>
                                    
                                    in <span class="textVeryHealthy">Very Healthy</span><br>
                                    compared to <span class="RightTopSummaryDate greenCoverColor">{{compareMonth.toUpperCase()}}</span> records
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                    
                    
                </v-layout>
            </v-card>
            </div>

            <!-- <div v-if="tab === 'Top 10'">

                <div class="topHeader">
                    <p style="color:black;" class="ml-4 mt-2 mb-0 pl-2"><span class="font-weight-bold text-uppercase">Top 10 change results</span> for 
                        <span class="greenCoverColor font-weight-bold">AOI_PT_ZHONG,_PT_BPN_DAN_PT_DRI</span> 
                        for the month of 
                        
                        <v-autocomplete
                            v-model="dateto"
                            :items="TableMonths"
                            dense
                            hide-selected
                            hide-detail
                            placeholder="Select Month "
                            @change="changeTo"
                            class="greenCoverColor"
                        >
                        </v-autocomplete>
                        </p>
                <v-btn-toggle
                    v-model="resultMode"
                    mandatory
                    group
                    class="ml-5 justify-space-around"
                >
                    <v-btn 
                        class="mx-0 topBtn greenCoverColor" height="30"
                    value="topTenGreen"
                    outlined
                        :style="{'background': resultMode === 'topTenGreen'? '#FFFFFF' : 'transparent'}">
                    <span style="padding-top: 3px; font-size: 12px">Top 10 Green                                    
                            <v-icon class="raiseTriangle" color="green">mdi-triangle
                            </v-icon>
                        </span>
                    </v-btn>

                    <v-btn 
                        class="mx-0 topBtn greenCoverColor" height="30"
                    value="btmTenGreen"
                        :style="{'bsckground': resultMode === 'btmTenGreen'? '1px solid #893FF2' : ''}">
                    <span style="padding-top: 3px; font-size: 12px">Top 10 Green <v-icon class="raiseTriangle mdi-rotate-180" color="red">mdi-triangle
                            </v-icon>
                            </span>
                    </v-btn>
                    <v-btn 
                        class="mx-0 topBtn greenCoverColor" height="30"
                    value="topTenNGreen"
                        :style="{'bsckground': resultMode === 'topTenNGreen'? '1px solid #893FF2' : ''}">
                    <span style="padding-top: 3px; font-size: 12px">Top 10 Non Green <v-icon class="raiseTriangle" color="green">mdi-triangle
                            </v-icon></span>
                    </v-btn>
                    <v-btn 
                        class="mx-0 topBtn greenCoverColor" height="30"
                    value="btmTenNGreen"
                        :style="{'bsckground': resultMode === 'btmTenNGreen'? '1px solid #893FF2' : ''}">
                    <span style="padding-top: 3px; font-size: 12px">Top 10 Green <v-icon class="raiseTriangle mdi-rotate-180" color="red">mdi-triangle
                            </v-icon></span>
                    </v-btn>
                </v-btn-toggle>
            </div>
                <v-data-table
                    :headers="headers"
                    :items="topGreen"
                    class="elevation-1"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:item.change="{ item }">
                        {{item.change}}
                        <v-icon v-if="item.change > 10" color="green">mdi-triangle</v-icon> 
                        <v-icon v-else color="red" class="mdi-rotate-180">mdi-triangle</v-icon>
                    </template>
                </v-data-table>
            </div> -->
        </div>

    </v-container>
</template> 

<script>
import tooltip from '@/components/home/LeftSidePanel/tooltip'
import MonthPicker from "@/components/MonthPicker"
import axios from "axios";

export default {
    components:{
        MonthPicker,
        tooltip
    },
     props: {
         summary: {
             type: Object,
             required: true
         },
	month: {
             type: Number,
             required: true
         },
	localMonth: {
             type: String,
             required: true
         },
         globalYear: {
             type: String,
             required: true
         }	 

    },
  created () {
    this.cmpmonth_model = this.compareMonth
  },	
  async beforeMount() {
    //   let InitialReq = await axios.get('https://imoa.eofactory.ai:3443/api/v1/aois', {
    //       headers: {
    //           'X-Authorization': 'H50jsyR7JpDwj7sVoU86hkoaN1Pr6osF2xyJWaKTNnqK27jx5Oft1NLRsHB7DCgt',
    //           'Accept': 'application/json'
    //       }
    //   })
    //   .then((res)=> {
    //     //   console.log('All AOIS: ',res.data)
    //     return res.data
    //   })
    //   .catch((error) => {
    //     // console.error(error)
    //     return error
    //     })

        // console.log('From Tab:', InitialReq.data)

    
    // let all = ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']

    // all.forEach( async (row) => {
    //         let GetLayersReq = await axios.get('https://imoa.eofactory.ai:3443/api/v1/layers', {
    //             headers: {
    //                 'X-Authorization': 'H50jsyR7JpDwj7sVoU86hkoaN1Pr6osF2xyJWaKTNnqK27jx5Oft1NLRsHB7DCgt',
    //                 'Accept': 'application/json'
    //             },
    //             params:{
    //                 'from_date': '11-2021',
    //                 'to_date':'02-2022',
    //                 'aoi_ids': row
    //             }
    //         })
    //         .then((res)=> {
    //             res.data.aoi = row;
    //             console.log('All Layers: ',res.data)
    //             this.allData.push(res.data)
    //             return res.data
    //         })
    //             .catch((error) => {
    //             // console.error(error)
    //             return error
    //         })
    // })

    //  let GetLayersReq = await axios.get('https://imoa.eofactory.ai:3443/api/v1/layers', {
    //       headers: {
    //           'X-Authorization': 'H50jsyR7JpDwj7sVoU86hkoaN1Pr6osF2xyJWaKTNnqK27jx5Oft1NLRsHB7DCgt',
    //           'Accept': 'application/json'
    //       },
    //       params:{
    //           'from_date': '11-2021',
    //           'to_date':'02-2022',
    //           'aoi_ids': '01,05,06,07,08,09,10,22'
    //       }
    //   })
    //   .then((res)=> {
    //       console.log('All Layers: ',res.data)
    //     return res.data
    //   })
    //   .catch((error) => {
    //     // console.error(error)
    //     return error
    //     })

        // console.log('From Tab: ', GetLayersReq);


  },
    data() {
        return {
            compareMonth: '',
			cmpmonth_model : undefined,
            tab: 'Green Cover',
            months: ['January','February','March','April','May','June','July','August','September','October','November','December'],
            TableMonths: ['September','October','November'],
            items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
            // dateto: null,
            resultMode: '',
            valid: '',
            picker: '',
            localYear: '2022',

            // headers: [
            //     {
            //     text: 'AOI NAME',
            //     align: 'start',
            //     sortable: false,
            //     value: 'aoi',
            //     },
            //     { text: 'PROVINCE', value: 'prov' },
            //     { text: 'TYPE OF MINE', value: 'mine' },
            //     { text: 'CLOUD % FROM', value: 'from' },
            //     { text: 'CLOUD % TO', value: 'to' },
            //     { text: '% CHANGE', value: 'change' }
            //     // { text: 'Increase/Decrease', value: 'status' }

            // ],
            topGreen: [
                {
                aoi: 'lahan_open_over_layer_2',
                prov: 'Sumatera Selatan',
                mine: 'Batu Bara',
                change: 2,
                from: 10,
                to: 5,
                // status: true
                },
                {
                aoi: 'Kuantan_Singingi_Riau',
                prov: 'Maluku Utara',
                mine: 'Mineral',
                change: '15',
                from: 6,
                to: 3,
                // status: false
                
                },
                {
                aoi: 'lahan_open_over_layer_5',
                prov: 'Riau',
                mine: 'Batu Bara',
                change: 20
                },
                {
                aoi: 'lahan_open_over_layer_1',
                prov: 'Sumatera Selatan',
                mine: 'Batu Bara',
                change: 5
                },
                {
                aoi: 'Sultra_bdy10',
                prov: 'Sulawesi Tenggara',
                mine: 'Mineral',
                change: 24
                },
                {
                aoi: 'Delta Kencana Sejahtera_region',
                prov: 'Kepulauan Bangka Belitung',
                mine: 'Mineral',
                change: 4
                },
                {
                aoi: 'Kaltim3',
                prov: 'Kalimantan Timur',
                mine: 'Batu Bara',
                change: 8
                },
                {
                aoi: 'Agung Sangun Jaya_region',
                prov: 'Kepulauan Bangka Belitung',
                mine: 'Mineral',
                change: 10
                },
                {
                aoi: 'Sultra_bdy7',
                prov: 'Sulawesi Tenggara',
                mine: 'Mineral',
                change: 7
                },
                {
                aoi: 'Kaltim14',
                prov: 'Kalimantan Timur',
                mine: 'Batu Bara',
                change: 15
                }
            ],
            
        }
    },
    watch: {
    compareMonth (val) {
      this.cmpmonth_model = val
            //  localYear
            //  this.localYear = date.substring(0,4)
            // this.compareMonth = this.months[(parseInt(date.substring(5,7)) - 1)]
            // compareMonth.toUpperCase()
    //   this.dateto = this.compareMonth;
    },
    month: {
        deep: true,
        handler: function(){
            // console.log('Prop Changed: ', newVal)
            this.changeTo();
        }
    }
  },
    computed: {
        computedDateFormatted() {
            return this.formatDate(this.picker);
        },
	
	//    cmpmonth: {
    //   get () {
    //     return this.cmpmonth_model.name
    //   },
    //   set (val) {
    //     this.cmpmonth_model.name = val
	// 	var i = 1
	// 	this.months.forEach((m) => {
	// 	if (m == val)
	// 	{
	// 	this.cmpmonth_model.value = (i < 10 ? '0' : '') + String(i)
	// 	}
		
	// 	i = i + 1
	// 	})
		
    //     this.$emit('input', this.cmpmonth_model)
    //   }
    // },
    month_label () {
  
		var idx = Number(this.month) - 1
		
		if (idx < 1)
		idx = 0
      
      return this.months[idx].toUpperCase() 
    }
    },
    methods: {
        monthPicker(date){
            // console.log('Input:', date)
            // compareMonth
            this.localYear = date.substring(0,4)
            this.compareMonth = this.months[(parseInt(date.substring(5,7)) - 1)]
            // console.log('Local Month: ', this.compareMonth)
            // this.compareMonth = 
            this.$emit('chgLocalMonth', date)
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month] = date.split("-");
            console.log(`${month}-${year}`);
            return `${month}-${year}`;
        },

        Tab(x){
            this.tab = x;
            console.log(this.tab)
        }, 
        // changeTab(tab){
        //     // console.log('From Tab Btn:', tab.target.innerText)
        //     let event = tab.target.innerText.trim()
        //     // console.log('event Text: ',tab.target.innerText)

        //     let render = false
        //     if (event === 'GREEN COVER & GREEN DENSITY'){
        //         render = false;
        //         this.$emit('tabChange', render)
        //     }
        //     else if (event === 'TOP 10 CHANGE RESULTS'){
        //         render = true;
        //         this.$emit('tabChange', render);
        //     }
        //     console.log('Emitted?', render)
        //     // console.log('From Tab Model:', this.tab)
        // },
        changeTo(){
            // this.headers[4].text = 'Change % '+ this.dateto
            // console.log('cmpmonth_model: ', this.cmpmonth_model);
            // console.log('cmpmonth: ', this.cmpmonth)
            // console.log('compareMonth', this.compareMonth)
            // console.log('Month:', this.month)
            //  this.headers[3].text = 'Change % '+ this.months[this.month - 1]
            //  console.log('From Month: ', this.months[this.month])
            console.log('Function to be Replaced')
        }
    },
    mounted() {
        // console.log('Mount: ',this.localMonth);
        this.compareMonth = this.months[(parseInt(this.localMonth.substring(5,7)) - 1)]
        this.compareMonth = this.compareMonth.toUpperCase()
    },
    
}
</script>
<style scoped>
.v-progress-linear{
    width: 60%;
    margin: auto auto;
}

.summarysqkm{
    font-size: 14px;
}

/* For position triangle arrow */
.raiseTriangle {
    position: relative;
    bottom: 5px;
}
/* Date Border bottom */
.RightTopSummaryDate{
 border-bottom: dashed 1px black ;
}

/* Right Panel Text styling */
.topSummaryRightPanel{
    font-size: 19.8349px;
    line-height: 23px;
}

/* For Percentage font sizing */
.rowOneIndicator{
    font-size: 40px;
    line-height: 47px;
    min-width: 120px !important;
}

/* Tab Buttons at the top */
.tabBtn{
 padding: 0 8px;
 box-shadow: none;
}
.tabBtn:active{
    color: #893FF2;
    border-radius: 2px;
    border-top: solid 2px #893FF2;
}

/* Frame for White box */
.summaryBox{
background: #FFFFFF;
box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33);
border-radius: 11px;

}

/* Maintaining width to not have x scrollbar in left panel */
.summaryContainer{
    width: calc(100% - 4px);
}

/* 2 Grid Panel Box Height */
.panelBox{
    height: 240px;
    margin: 0 5px;
}

/* Left Panel text sizing of 2 grid panel */
.greenCoverPanelText{
    font-size: 19px;
    line-height: 23px;
}

/* 2 grid number figure */
.greenCoverFigure {
    font-size: 54px;
    line-height: 45px;
    letter-spacing: 0.03em;
}

.greenCoverTM {
    margin-top: 28px;
}

.panel-BoxLM{
    margin-left: 30px !important;
    
}
.panelBoxRM{
    margin-right: 30px !important;
}

/* 2 Grid Panel Listing font sizing */
.panelSmallerCategory {
    margin: 4px 0;
    font-size: 15.68px;
    line-height: 18px;
}

.SummarySubHeading{
    font-size: 15px;
line-height: 18px;
letter-spacing: 0.05em;
}

.SelectionBox{
    box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.2), inset 0px 0px 4px rgba(137, 63, 242, 0.33);
    border-radius: 7px;
}

.topSectionBox{
    padding: 15px 15px 0px !important;
}
.topBtn{
    background: rgba(255, 255, 255, 0.22);
    box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.35), inset 0px 0px 4px rgba(137, 63, 242, 0.33);
    border:solid 1px #893FF2;
    border-radius: 7px !important;
}
button.topBtn.v-btn.v-btn--active{
    color: #893FF2  !important;
}

button.tabBtn.v-btn.v-btn--active{
    border-top: solid 3px #893FF2;
    color: #893FF2  !important;
}

.topHeader{
    background: rgba(137, 63, 242, 0.11);
    border-radius: 7px 7px 0px 0px;
    height: 96px;
}
/* .v-btn-toggle--group > .v-btn.v-btn{
    border-color: ;
} */

.v-input.v-input--is-focused.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder.v-select.v-select--is-menu-active.v-autocomplete.primary--text,
.v-input.greenCoverColor.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder.v-select.v-autocomplete{
    display: inline-block;
}
.v-select__slot input{
    color: #893FF2 !important;
    font-weight: 700;
    text-transform: uppercase;
}

td.text-start{
    line-height: 12px;
    text-transform: uppercase;
}
.alignColumn{
    display: flex;
}
</style>