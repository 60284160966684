<template>
    <v-tooltip right class="white">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          class="tooltipBtn"
          v-bind="attrs"
          v-on="on"
        >
          ?
        </v-btn>
      </template>
      <div class="d-flex flex-column tooltipContainer pa-3 white">
          <v-row class="pa-0 py-0 tooltipDiv">
          <v-col cols="6" class="pa-0 ma-0 textStress">0 TO 0.2</v-col>
          <v-col cols="6" class="pa-0 ma-0 textStress">Stressed</v-col>
          </v-row>
          <v-row class="pa-0 py-0 tooltipDiv">
          <v-col cols="6" class="pa-0 ma-0 textSlightStress">0.2 TO 0.4</v-col>
          <v-col cols="6" class="pa-0 ma-0 textSlightStress">Slightly Stressed</v-col>
          </v-row>
          <v-row class="pa-0 py-0 tooltipDiv">
          <v-col cols="6" class="pa-0 ma-0 textModerate">0.4 TO 0.6</v-col>
          <v-col cols="6" class="pa-0 ma-0 textModerate">Moderate</v-col>
          </v-row>
          <v-row class="pa-0 py-0 tooltipDiv">
          <v-col cols="6" class="pa-0 ma-0 textHealthy">0.6 TO 0.8</v-col>
          <v-col cols="6" class="pa-0 ma-0 textHealthy">Healthy</v-col>
          </v-row>
          <v-row class="pa-0 py-0 tooltipDiv">
          <v-col cols="6" class="pa-0 ma-0 textVeryHealthy">0.8 </v-col>
          <v-col cols="6" class="pa-0 ma-0 textVeryHealthy">Very Healthy</v-col>
          </v-row>
      </div>
    </v-tooltip>
</template>
<script>
export default {
}
</script>
<style scoped>
.tooltipContainer{
    width: 210px;
    height: 140px;
}
.v-tooltip__content{
    background: white;
    box-shadow: -1px 0px 5px rgba(128, 124, 133, 0.45);
    border-radius: 4px;
}

.tooltipDiv{
    height: 20px;;
}

.tooltipBtn{
    height: 18px;
    width: 18px;
    background: rgba(181, 135, 247, 0.22);
}
</style>