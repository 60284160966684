<template>
  <v-dialog
      v-model="downloadDialog"
      max-width="500"
  >
    <v-card>
      <v-overlay :value="loading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title style="height: 64px; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22);">
        <span style="font-size: 16px">REPORT</span>
        <v-spacer/>
        <v-btn icon small @click="downloadDialog = false">
          <img alt="download" :src="require('@/assets/icon/close.svg')">
        </v-btn>
      </v-card-title>

      <v-card-text style="max-height: 70vh; overflow-y: auto" class="pa-0">
        <v-layout class="fill-height">
          <v-data-table
              fixed-header
              style="height: 100%"
              height="100%"
              :headers="headers"
              :items="allReport"
              item-key="id"
              class="elevation-1"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn icon target="_blank" x-small color="green" :href="item.url"><v-icon>mdi-download</v-icon></v-btn>
            </template>
          </v-data-table>
        </v-layout>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {getImageries, downloadImages, getListReport} from "@/backend";
import MonthTransform from "@/ultis/converMonth";

export default {
  name: "SelectPdf",
  data () {
    return {
      headers: [
        {
          text: 'Report Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center"
        }
      ],
      allReport: [],
      loading: false,
      downloadDialog: false,
      imageType: ''
    }
  },
  computed: {
  },
  methods: {
    openDialog(type) {
      this.imageType = type
      this.getData()
      this.downloadDialog = true
    },
    convertMonth(month) {
      return MonthTransform.transformMonthText(month)
    },
    async getData() {
      try {
        this.loading = true
        const res = await getListReport()
        this.allReport = res.data.data
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
