<template>
  <div class="bg">
    <div class="pl-timeline">
      <div class="pl-timeline-page-left visible">
        <div>
          <svg
            @click="scrollLeft()"
            class="pl-icon"
            viewBox="0 0 12 7.41"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1.41,0L6,4.58,10.59,0,12,1.41l-6,6-6-6Z"/>
          </svg>
        </div>
      </div>

      <div
        class="pl-timeline-dates month">
        <div
          ref="box"
          @mousedown="boxMouseDown"
          @mouseup="boxMouseUp"
          @mousemove="boxMouseMove"
          @mouseenter="boxMouseUp"
          class="pl-timeline-draggable">
          <div
            ref="content"
            style="display: flex">
            <div
              v-for="item of items"
              :key="item.id">
              <!-- <div
                v-if="item.divyear"
                class="pl-timeline-marker">
                <div>{{ item.year }}</div>
              </div> -->
              <div
                v-if="!item.divyear"
                class="pl-timeline-period"
                :class="{ 'selected': selectedItem === item, 'no-scenes': !item.available }"
                @click="selectItem(item)">
                <div
                  class="label">{{ item.month }}
                </div>
                <div class="pl-tooltip">
                  <div class="pl-tooltip-target"/>
                  <div class="pl-tooltip-label pl-top">
                     {{ item.label }}
                    <!-- <div>{{ item.total }}</div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pl-timeline-page-right">
        <div>
          <svg
            @click="scrollRight()"
            class="pl-icon"
            viewBox="0 0 12 7.41"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1.41,0L6,4.58,10.59,0,12,1.41l-6,6-6-6Z"/>
          </svg>
        </div>
      </div>
      <div class="pl-timeline-interval-selector"/>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'TimeLine',
  props: {
    date: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      default: () => []
      /**
       * {
        year: string,
        divyear: boolean,
        day: Date(),
        date: number,
        available: boolean
      }
       */
    },
    selected: {
      type: Object,
      default: undefined
    }
  },

  data () {
    return {
      selectedItem: undefined,
      clicked: false,
      pageX: 0
    }
  },

  watch: {
    date (val) {
	console.log(val)
     // this.selectedItem = this.items.find(e => {
      //  return dayjs(e.day).format().split('T')[0] === dayjs(val).format().split('T')[0]
     // })
    },

    items (val) {
      if (val.length > 0 && this.selectedItem === undefined && this.selected !== undefined) {
        this.selectedItem = this.selected
      }
    }
  },

  created () {
    // this.binToView()
  },

  methods: {
    scrollLeft () {
      const box = this.$refs.box
      if (box.scrollLeft <= 200) {
        this.$emit('leftLoadMore')
      } else {

      }
      box.scrollLeft -= 200
    },

    scrollRight () {
      const box = this.$refs.box
      box.scrollLeft += 200
    },

    scrollRightMax () {
      this.$nextTick(() => {
        const box = this.$refs.box
        box.scrollLeft += this.$refs.content.offsetWidth
      })
    },

    boxMouseDown (e) {
      this.clicked = true
      this.pageX = e.pageX
    },

    boxMouseUp (e) {
      this.clicked = false
    },

    boxMouseMove (e) {
      if (this.clicked) {
        this.$refs.box.scrollLeft += (this.pageX - e.pageX) * 2
        this.pageX = e.pageX
      }
    },

    cutYear (str) {
      const patt1 = /\d+/g
      const result = str.match(patt1)
      return result
    },

    conCat (str) {
      return str
    },

    selectItem (item) {
	    // console.log('Child TimeLine: ',item)
      this.selectedItem = item
      this.$emit('changeTime', item)
    }
  }
}
</script>

<style src="@/assets/css/timeline-font.css" scoped>

</style>

<style scoped>
.bg {
  width: 100%;
}

.pl-timeline {
  width: 100%;
  margin: auto;
  position: relative;
  color: black;
  background: transparent;
}

.pl-timeline .pl-timeline-page-left svg, .pl-timeline .pl-timeline-page-right svg {
  fill: gray;
}

.pl-timeline .pl-timeline-interval-selector {

}

.pl-timeline .pl-timeline-dates .pl-timeline-period .pl-tooltip .pl-tooltip-label {
  width: auto
}

.pl-timeline .pl-timeline-dates .pl-timeline-period.selected:after {
  background-color: var(--brand-primary);
}
</style>
