import axios from 'axios'
import { RateLimit } from '@/ultis/RateLimit'

const rateLimit = new RateLimit(2, 500)

const END_POINT = 'https://api.planet.com/data/v1'
// const API_KEY = window.config.PLANET_API_KEY
const API_KEY = '562df20ffb5841dc8f44b303aad34bdf'

export class PlanetApi {
  constructor() {
    this.itemTypes = null

    this.getItemTypes().then((types) => {
      this.itemTypes = types
    })
  }

  request(options) {
    options.url = `${END_POINT}${options.url}`

    options.auth = {
      username: API_KEY,
      password: '',
    }

    options.headers = {
      'Content-Type': 'application/json',
    }

    return axios(options)
  }

  async getItemTypes() {
    // const res = await this.request({
    //   method: 'GET',
    //   url: '/item-types',
    // })

    // return res.data.item_types

    return Promise.resolve([
      {
        _links: {
          _self: 'https://api.planet.com/data/v1/item-types/PSScene3Band',
        },
        display_description:
          '3-band (RGB) PlanetScope imagery that is framed as captured',
        display_name: '3-band PlanetScope Scene',
        id: 'PSScene3Band',
      },
    ])
  }

  /**
   * @public
   * @param {Object} geometry geojson geometry
   * @param {String} startDate ISO Date String
   * @param {String} endDate ISO Date String
   * @param {Number} cloudCover Cloud cover percentage [0..100]
   * @param {String} interval 'hour', 'day', 'month', 'year'
   */
  stats(geometry, startDate, endDate, cloudCover, sunElevation, interval) {
    return new Promise((resolve, reject) => {
      rateLimit.run(() => {
        // console.log('STATS', new Date().toISOString())
        this.performStats(
          geometry,
          startDate,
          endDate,
          cloudCover,
          sunElevation,
          interval
        )
          .then(resolve)
          .catch(reject)
      })

      rateLimit.run(() => {
        // Empty function call for OPTIONS request alongside real stats request
        // console.log('OPTIONS', new Date().toISOString())
      })
    })
  }

  /**
   * @private
   * @param {Object} geometry geojson geometry
   * @param {String} startDate ISO Date String
   * @param {String} endDate ISO Date String
   * @param {Number} cloudCover Cloud cover percentage [0..100]
   * @param {String} interval 'hour', 'day', 'month', 'year'
   */
  async performStats(
    geometry,
    startDate,
    endDate,
    cloudCover = 20,
    sunElevation = [0, 90],
    interval
  ) {
    const data = await this.createRequestData(
      geometry,
      startDate,
      endDate,
      cloudCover,
      sunElevation,
      interval
    )

    const res = await this.request({
      method: 'POST',
      url: '/stats',
      data: data,
    })

    return res.data
  }

  /**
   * @public
   * @param {Object} geometry geojson geometry
   * @param {String} startDate ISO Date String
   * @param {String} endDate ISO Date String
   * @param {Number} cloudCover Cloud cover percentage [0..100]
   */
  async quickSearch(
    geometry,
    startDate,
    endDate,
    cloudCover = 20,
    sunElevation = [0, 90]
  ) {
    const data = await this.createRequestData(
      geometry,
      startDate,
      endDate,
      cloudCover,
      sunElevation
    )

    const res = await this.request({
      method: 'POST',
      url: '/quick-search',
      data: data,
    })

    return res.data.features.map((feature) => {
      feature.properties.tile_url = `https://tiles{s}.planet.com/data/v1/${feature.properties.item_type}/${feature.id}/{z}/{x}/{y}.png?api_key=${API_KEY}`
      feature.properties.subdomains = [0, 1, 2, 3]

      return feature
    })
  }

  async createRequestData(
    geometry,
    startDate,
    endDate,
    cloudCover = 20,
    sunElevation = [0, 90],
    interval = 'day'
  ) {
    if (!this.itemTypes) {
      this.itemTypes = await this.getItemTypes()
    }

    const geomFilter = await this.getGeomFilter(geometry)

    const data = {
      //interval: interval,
      item_types: this.itemTypes.map((type) => type.id),
      filter: {
        type: 'AndFilter',
        config: [
          geomFilter,
          {
            type: 'DateRangeFilter',
            field_name: 'acquired',
            config: {
              gte: startDate,
              lte: endDate,
            },
          },
          {
            type: 'RangeFilter',
            field_name: 'cloud_cover',
            config: {
              lte: cloudCover / 100,
            },
          },
          {
            type: 'RangeFilter',
            field_name: 'sun_elevation',
            config: {
              gte: sunElevation[0],
              lte: sunElevation[1],
            },
          },
        ],
      },
    }
    return data
  }

  async getGeomFilter(geom) {
    if (geom.length > 1) {
      return {
        type: 'OrFilter',
        config: geom.map((g) => {
          return {
            type: 'GeometryFilter',
            field_name: 'geometry',
            config: g,
          }
        }),
      }
    } else if (geom.length === 1) {
      return {
        type: 'GeometryFilter',
        field_name: 'geometry',
        config: geom[0],
      }
    }
  }

  // async getAssets(image) {
  //   const res = await this.request({
  //     method: 'GET',
  //     url: image._links.assets.replace('https://api.planet.com/data/v1', ''),
  //   })

  //   return res.data
  // }

  // async activateAsset(asset) {
  //   const res = await this.request({
  //     method: 'POST',
  //     url: asset._links.activate.replace('https://api.planet.com/data/v1', ''),
  //   })

  //   return res.data
  // }

  // async getBaseMapMosaics() {
  //   const res = await this.request({
  //     method: 'GET',
  //     url: '/basemaps/v1/mosaics',
  //     params: {
  //       public: true,
  //     },
  //   })

  //   return res.data.mosaics
  // }
}

const api = new PlanetApi()

export default api
