<template>
  <v-card flat style="height: calc(100vh - 120px); background: #f4ecfd; border-radius: 0px">
    <v-overlay :value="loading" absolute z-index="99999">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card-text class="fill-height" style="border-radius: 10px; padding: 0px 36px 16px 36px">
        <div class="mr-6" style="border-radius: 10px; width: 100%; height: 100%; border: 1px solid rgba(137, 63, 242, 0.2); box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22); background-color: #fff">
          <div style="border-top-right-radius: 10px; border-top-left-radius: 10px; height: 70px; border: 1px solid rgba(137, 63, 242, 0.2); box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.22); background: linear-gradient(180deg, #EFEFEF 76.97%, rgba(227, 227, 227, 0.28) 100%)" class="px-0 py-1">
            
            <v-layout class="fill-height">
              <div class="fill-height" style="width: 50%">
                <!-- AOI Overview Dropdown Menu -->
                <v-layout class="fill-height px-5" align-center>
                  <span style="font-size: 0.8vw; font-weight: 700;">Overview of Changes in </span>
                  <div style="width: 300px" class="pl-3">
                    <!-- Dropdown Menu itself -->
                    <!-- <v-autocomplete
                        flat
                        class="elevation-0 select-input"
                        @change="changeAOI"
                        v-model="aoi"
                        :items="aois"
                        solo
                        dense
                        hide-details
                        item-text="name"
                        return-object/> -->
                        <!-- <span v-if="dropdown === false">
                            <allDropdown @change="changeAOI"></allDropdown>
                        </span>
                        <span v-else-if="dropdown === true">
                            <TopDropdown @change="changeAOI"></TopDropdown>
                        </span> -->
                        <searchBox @aoiSelected="changeAOI"></searchBox>
                        
                  </div>

                   

                  <span class="font-weight-black ml-8">AREA <span class="mx-1">{{Math.ceil(aoi_area)}}</span> sqkm<span>&#178;</span></span>
                </v-layout>
              </div>
              <!-- MonthPicker Slider -->
              <div class="flex fill-height pl-3">
                    <TimeLine
        ref="timeLine"
        :date.sync="date"
        :items="mths"
        @changeTime = "timelineMonth"
      />
                <!-- <v-layout class="fill-height px-2" align-center>
                  <v-btn @click="currentPeriod = year[0]" icon>
                    <v-icon class="mdi-rotate-270">mdi-triangle</v-icon>
                  </v-btn>
                  <span class="mr-4" style="color: #893FF2; font-size: 13px; font-weight: 700">{{currentPeriod.first}}</span>
                  <v-card style="border-radius: 8px; margin-top: -10px" width="100%" height="32" class="px-2">
                   
                    <v-slider
                        ref="monthSlider"
                        @change="getData"
                        class="monthRanger"
                        color="#893FF2"
                        v-model="month"
                        :tick-labels="monthLabels"
                        :max="sliderMax"
                        :min="sliderMin"
                        step="1"
                        ticks="always"
                        tick-size="4"
                    ></v-slider>

                  </v-card>
                  <span class="ml-4" style="color: #893FF2; font-size: 13px; font-weight: 700">{{currentPeriod.last}}</span>
                  <v-btn @click="currentPeriod = year[1]" icon>
                          <v-icon class="mdi-rotate-90">mdi-triangle</v-icon>
                  </v-btn>
                </v-layout> -->
              </div>
            </v-layout>
          </div>
          <div style="height: calc(100% - 70px);">
            <v-layout class="fill-height pa-3">
              <v-layout class="fill-height pa-3 elevation-2 PanelBox LP-Background" style="border-radius: 10px;">
                <v-layout style="height: 100%; width: 50%; border-radius: 11px 0 0 11px;" class="overflow-y-auto elevation-2" column>
				
                <allTabs 
                  :summary="summary" 
                  :month="month" 
                  :globalYear = "currentPeriod.year"
                  :localMonth="localMonth" 
                  @chgLocalMonth="chgLocalMonth" 
                  >
                </allTabs>
                </v-layout>
                <div style="height: calc(100% - 5px); width: 50%;" class="ml-6 d-flex flex-column">
                  <div style="width: 100%; height: 50%; border-top: 1px solid lightgray">
                    <v-layout align-center style="height: 45px; background-color: #f4f3f6" class="px-6 elevation-2">
                      <span class="text-header text-uppercase" style="letter-height: 16px; letter-spacing: 0.1em; color: #893FF2; font-weight: 500;">Map Overview</span>
                    </v-layout>
                    <div style="height: calc(100% - 45px);">
                      <BaseMap
                          ref="mapView"
                       
                          :dataSource.sync="dataSource"
                          @getData="getData"
                          @gotoMapView="() => $emit('gotoMapView')"
                      />
                         <!-- :layers.sync="mapLayers"  -->
                    </div>
                  </div>
                  <div style="width: 100%; height: 50%">
                    <v-layout align-center style="height: 45px; flex: none" class="px-6">
                      <span class="text-header" style="font-size: 1.2vw; color: #893FF2; letter-spacing: 0.1em; line-height: 23px;">DATA GRAPH</span>
                      <v-spacer/>
                      <v-btn icon small color="green" @click="refreshChart"><v-icon>mdi-refresh</v-icon></v-btn>
                    </v-layout>
                    <div class="px-2 flex d-flex" style="height: calc(100% - 45px);">
                      <v-layout class="fill-height">
                        <v-layout class="fill-height pr-3">
                          <canvas style="height: 100%; width: 100%; flex: none" id="myChart"></canvas>
                        </v-layout>
                        <div id="legend-container" style="width: 33%; height: 100%; overflow-y: auto; flex: none" class="pt-3">
                          <v-expansion-panels multiple flat class="legend-panel"
                          v-model="panel">
                          <!-- v-model="panel" make tab active on load -->
                            <v-expansion-panel
                                
                                class="py-1 px-1"
                                v-for="(group, i) in groupLegend"
                                :key="i"
                            >
                              <v-expansion-panel-header style="box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25); border-radius: 7px">
                                <!-- <v-layout class="fill-height" align-center :style="{'color': group.legend[0].strokeStyle}"> -->
                                  <v-layout class="fill-height" align-center :style="{'color': '#649f25'}">
                                  
                                  <v-icon size="22" class="mr-1" :color="group.legend[0].strokeStyle">mdi-drag</v-icon>
                                  <!-- <v-checkbox
                                      @click.native.stop="ChangeDisplayGroupLegend(group)"
                                      class="small-radio"
                                      v-model="group.checkAll"
                                      :color="group.legend[0].strokeStyle">
                                  </v-checkbox> -->
                                  <v-checkbox
                                      @click.native.stop="ChangeDisplayGroupLegend(group)"
                                      class="small-radio"
                                      v-model="group.checkAll"
                                      color="#649f25">
                                  </v-checkbox>
                                  <!-- {{group.name}} --> Plant Health
                                </v-layout>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-list dense height="100%" class="py-0">
                                  <v-list-item v-for="(label, index) of group.legend" :key="'legend-'+index" style="min-height: 0;height: 30px" class="px-1" @click="changeDisplayChart(label)">
                                    <v-list-item-action>
                                      <v-icon size="22" v-if="label.hidden" :color="label.fillStyle">mdi-checkbox-blank-outline</v-icon>
                                      <v-icon size="22" v-else :color="label.fillStyle">mdi-checkbox-marked</v-icon>
                                    </v-list-item-action>
<!--                                    <v-list-item-action>-->
<!--                                      <div style="width: 20px; height: 20px; max-height: 30px"-->
<!--                                           :style="{'background-color': label.fillStyle, 'border': `2px solid ${label.strokeStyle}`}">-->
<!--                                      </div>-->
<!--                                    </v-list-item-action>-->
                                    <span class="text-truncate" :title="label.text" style="font-size: 12px" :style="{'color': label.fillStyle, 'text-decoration': label.hidden ? 'line-through' : ''}">{{label.text}}</span>
                                    <v-spacer/>
                                  </v-list-item>
                                </v-list>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </div>
                      </v-layout>
                    </div>
                  </div>
                </div>
              </v-layout>
            </v-layout>
          </div>
        </div>
    </v-card-text>
  </v-card>
</template>

<script>
import BaseMap from "@/components/home/dataSource/BaseMap"
import bbox from '@turf/bbox'
import Chart from "chart.js/auto"
import {getAOI,getAOIResults, getDataDashBoard} from "@/backend";
import axios from "axios";
import overviewAPI from "@/api/overview.js";
import MonthTransform from "@/ultis/converMonth";
import numberFormat from "@/ultis/comma";
import randomColor from "@/ultis/randomColor";
import allTabs from  "../LeftSidePanel/Tab";
// import Menu from "@/components/home/LeftSidePanel/dropdown";
import allDropdown from "@/components/home/mainMenu";
import aois_info from '@/assets/AOI_Planet.json'
import area from '@turf/area'
import * as turf from '@turf/turf'
import TopDropdown from "@/components/home/top10Menu";
import search from "@/components/home/aoiAllMenu";
import allAOI from '@/assets/AOI_Planet.json'
import TimeLine from './Timeline'

// const testAPI = new EOfactoryApi();
// console.log(testAPI);
// console.dir('Top:', overviewAPI.getDetails());

let date = new Date()
export default {
  name: "Datasource",
  components: { 
    BaseMap,
    allTabs,
    allDropdown,
    TopDropdown,
    'searchBox' : search,
    TimeLine
  },
  data () {
    return {
      // sliderMin: 1,
      // sliderMax: 12,
      	date: "04-2021",
	mths : [],
      all_area: 0,
	aoi_area : 0,
  allResponse: [],
	summary : {
    PlantHealth: {
          Left:{
              stressed: 70,
              stressed_sqkm: 0,
              slightstress: 60,
              slightstress_sqkm: 0,
              Moderate: 50,
              Moderate_sqkm: 0,
              Health: 80,
              Health_sqkm: 0,
              VeryHealthy: 30,
              VeryHealthy_sqkm: 0
          },
          Right:{
              stressed: 30,
              slightstress: 10,
              Moderate: 20,
              Health: 25,
              VeryHealthy: 15,
              stressed_sqkm: 0,
              slightstress_sqkm: 0,
              Moderate_sqkm: 0,
              Health_sqkm: 0,
              VeryHealthy_sqkm: 0
          }
      },
      changes: {
          stressed: 30,
          slightstress: 10,
          Moderate: 20,
          Health: 25,
          VeryHealthy: 15,
          stressed_sqkm: 0,
          slightstress_sqkm: 0,
          Moderate_sqkm: 0,
          Health_sqkm: 0,
          VeryHealthy_sqkm: 0
      }
		},
    
    year: [ 
      {
        "first": 'Jan 2021',
        "last": 'Dec 2021',
        'year': '2021'
      },
      {
        "first": 'Jan 2022',
        "last": 'Dec 2022',
        'year': '2022'
      }
    ],
      currentPeriod: {
        "first": 'Jan 2022',
        "last": 'Dec 2022',
        'year': '2022'
      },
      loading: true,
      chartLegend: [],
      monthLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      fullMonthLabel:  ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      aoi: {},
      aois: [],
      panel: [0],
      projectBbox: [114.148865,-3.448652,114.761352,-2.369611],
      // compareMonth: {
      //   name: 'October',
      //   value: '10'
      // },
      localMonth : '2021-02',
      greenCoverData: {},
      dataTableGreenDensity: [],
      dataTablePlantHealth: [],
      data: {},
      month: 1,
      labels: [],
      dropdown: false,
      dropdownOption: false,
      greenCoverChart: undefined,
      canopyCoverChart: undefined,
      chartFilters: [{
        name: 'DISTRICT',
        code: 'district'
      }, {
        name: 'AOI SELECTED',
        code: 'aoi_selected'
      }],
      chartData: [],
      chartType: 'overall_green_cover',
      imageDataChart: undefined,
      recordedFor: '2021-10',
      overViews: ['GREEN COVER'],
      myChart: undefined,
      mapLayers: [],
      allChart: [],
      chartPeriod: ''
    }
  },
  props: {
    dataSource: {}
  },
  beforeMount() {
      this.loading = true
        //& Get Current Month: 
      // let today = new Date()
      // let toMonth = today.getMonth() + 1
      // if (toMonth < 10){
      //   toMonth = '0' + toMonth.toString() + '-' + date.getFullYear().toString()
      //   //Actually change this.month & this.currentPeriod above
      // }
      // else{
      //   toMonth = toMonth.toString() + '-' + date.getFullYear().toString()
      //   //Actually change this.month & this.currentPeriod above
      // }
      // // //& Let last month
      // let fromMonth = today.getMonth()
      // if (fromMonth < 10){
      //   fromMonth = '0' + fromMonth.toString() + '-' + date.getFullYear().toString()
      // }
      // else{
      //   fromMonth = fromMonth.toString() + '-' + date.getFullYear().toString()
      // }

      // To load latest Mth when startup
      let currentPeriod = new Date();
      let currentMonth = currentPeriod.getMonth() + 1;
      let currentYear = currentPeriod.getFullYear();
      this.month = currentMonth - 1
      let localMth = currentMonth - 2
      let localYear = currentYear.toString()
      this.localMonth = localYear + "-0" + localMth.toString();

      // console.log('1) Current Month: ',currentMonth, ' Local Mth: ', this.localMonth, ' Global Month: ', this.month);


      if (currentMonth === 2){
          this.localMonth = "2023-01"
          this.month = 12
       }
      if (currentMonth === 1){
          this.localMonth = "2022-12"
          this.month = 11
      }

      
	  var y = 0
	  var ii = 0
	  
	  for (var i = 1 ; i < 25 ; i++)
	  {
	  
	if (i > 12)
	{
	ii = i - 12
	y = 2022
	}
	else
	{
	ii = i
	y = 2021
	}
	
	if (i < 12 && y >= 2021)
	{

    //@ To Show disable mth, but don't show it at all better
	// this.mths.push( {
  //   "year":  "NA",
  //   "divyear": true,
  //   "day": String(y),
  //   "date": y,
  //   "available": false
	// })	
        
	}
    else{
	
	 this.mths.push( {
     "label" : this.monthLabels[ii - 1] + '-' + String(y),
     "month": this.monthLabels[ii - 1],
    "year": String(ii) + '-' + String(y),
    "divyear": false,
    "day": String(ii) + '-' + String(y),
    "date": ii,
    "available": true
	})	
	
	}
    }

    console.log(this.mths);

      
      // this.month = 2
      // this.localMonth = "2022-11"

      // else {
      //     globalMth = this.month.toString() + '-' + this.currentPeriod.year.toString()
      // }
      //   newLocalMth = this.localMonth.substring(7,5) + '-' + this.localMonth.substring(0,4)
  },
  async mounted () {

    this.loading = true

      let currentPeriod = new Date();
      let currentYear = currentPeriod.getFullYear();

      let toFetchDate = ''
      if (this.month < 10){
        toFetchDate = '0' + this.month.toString()
      }
      else{
        toFetchDate = this.month.toString()
      }  

      let now =  toFetchDate + '-'+ currentYear.toString()

      let AllDataDate = [
        {month: '12', year: 2021},{month: '01', year: 2022},{month: '02', year: 2022},{month: '03', year: 2022},{month: '04', year: 2022},
        {month: '05', year: 2022},{month: '06', year: 2022},{month: '07', year: 2022},{month: '08', year: 2022},
        {month: '09', year: 2022},{month: '10', year: 2022},{month: '11', year: 2022},{month: '12', year: 2022},
      ]

      // & Get chart 
      let strAOI = '01,02,03,04,05,06,07,08,09,10,11,12,13,14,15,16,17,18,19,20,21,22,23'
      let res = ''
      // console.log('Now: ', now)
      res = await overviewAPI.getDetails(strAOI, '12-2021', now);
      // console.log('chartData: ',this.chartData);
      let allData = res.data
      
      let tempArr = ''
      let tempArrContainer = []
      // All Date Period
      AllDataDate.forEach((period)=>{
        // For Each date period, find in  obj
        tempArr = allData.filter(el => el.month === period.month && el.year === period.year)
        tempArrContainer.push(tempArr)
      })
      
      let chartHolder = {
        data: []
      }
      tempArrContainer.forEach((arr)=>{
        let obj = {
        month: '',
        year: 0,
          statistic: [
            {index:'Stressed', area: 0},
            {index:'Slightly Stressed', area: 0},
            {index:'Moderate', area: 0},
            {index:'Healthy', area: 0},
            {index:'Very healthy', area: 0},
          ]
        }
        if(arr.length > 1){
          obj.month = arr[0].month
          obj.year = arr[0].year
          arr.forEach((data)=>{
              obj.statistic[0].area = obj.statistic[0].area + data.statistic[0].area
              obj.statistic[1].area = obj.statistic[1].area + data.statistic[1].area
              obj.statistic[2].area = obj.statistic[2].area + data.statistic[2].area
              obj.statistic[3].area = obj.statistic[3].area + data.statistic[3].area
              obj.statistic[4].area = obj.statistic[4].area + data.statistic[4].area
          })
          // console.log('chartHolder',  chartHolder)
          chartHolder.data.push(obj)
          obj = {
            month: '',
            year: 0,
              statistic: [
                {index:'Stressed', area: 0},
                {index:'Slightly Stressed', area: 0},
                {index:'Moderate', area: 0},
                {index:'Healthy', area: 0},
                {index:'Very healthy', area: 0},
              ]
          }
        }
      })

      allData = chartHolder.data
      this.chartData = chartHolder
      // this.chartData = res
      try {
      this.AddToChart(allData, this.chartData);
      // console.log(this.chartData)
      this.aoi.name = 'ALL AOI'
      this.getData()
      this.$refs.mapView.addAOI(allAOI)
      this.loading = false
        
      } catch (error) {
        console.log('On Mount: ', error)
      }

      // & Cookies
      // console.log('Cookie: ', this.$cookie)
      //  console.log('Cookies: ', this.$cookies)
      // this.$cookie.get("latestMth")

      // if(!this.$cookie.get("latestMth")){
      //   this.$cookie.set("latestMth", "02-2022", 7776000)
      //   console.log("Cookie Inserted");
      // }
      // else{
      //   console.log('Ran When Cookie is there')
      // }
      // this.$cookie.set("latestMth", keyValue, "expiring time")
      //@ 10 Year cookie
      // setcookie(
      //   "CookieName",
      //   "CookieValue",
      //   time() + (10 * 365 * 24 * 60 * 60)
      // );
      // this.$cookies.get("username");



      
      // console.log()
      // aois_geojson
      // aois_info.features.forEach(a => {
      //   console.log('Info: ',a);
      //   var polygon = turf.polygon(a.geometry.coordinates[0])
      //    let aoi_area = Number((area(polygon)/1000000.0).toFixed(2))
      //     area = area + aoi_area
      //   }
      // )

      // this.aois_info = area;
      // console.log('All AOI: ', this.aois_info);

      // this.aois_info.forEach(a =>
      // {
      //   if (a.province == item.name || item.name == 'All Mine AOI')
      //   {
      //   area1 = area1 + a.area
      //   this.aoi_ids.push(a.id)
      //   }
      // }
      // )
  },
  computed: {
    months () {
      const months = require('@/assets/monthSelect.json')
      return months.filter(month => parseInt(month.value) <= parseInt(this.month))
    },
    groupLegend: function () {
      if (!this.myChart) return []
      let groups = []
	  
      this.myChart.data.datasets.forEach(dataset => {
        this.chartLegend.forEach(legend => {
          if (dataset.label.toUpperCase() === legend.text.toUpperCase()) {
            if (!groups.length) {
              groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            } else {
              let index = groups.findIndex(val => val.stack === dataset.stack)
              if (index >= 0) groups[index].legend.push(legend)
              else groups.push({
                name: dataset.stack,
                stack: dataset.stack,
                legend: [legend]
              })
            }
          }
        })
      })
      groups.forEach(group => {
        let totalDisplay = 0
        group.legend.forEach(legend => {
          if (!legend.hidden) totalDisplay = totalDisplay + 1
        })
        group['checkAll'] = totalDisplay === group.legend.length
      })
      return groups
    }
  },
  watch: {
    dataSource (val) {
      // this.getAOI()
      // this.getData()
    },
    month (val) {
      // if (parseInt(val) < parseInt(this.compareMonth.value)) 
      // this.compareMonth = this.months[0]
    },
    // currentPeriod(val){
    //   console.log('Current Period: ', val)
    //   console.log('Dom: ', this.$refs.monthSlider)
    //   if(val.year === '2021'){

    //     this.sliderMin = 11
    //     this.sliderMax = 12
    //     // this.$refs.monthSlider.min = 12
    //     // this.$refs.monthSlider.max = 12
    //     // this.$refs.monthSlider.minValue = 12
    //     // this.$refs.monthSlider.maxValue = 12
    //   }
    //   else{
    //     this.sliderMin = 1
    //     this.sliderMax = 12

    //     // this.$refs.monthSlider.min = 1
    //     // this.$refs.monthSlider.max = 12
    //     // this.$refs.monthSlider.minValue = 1
    //     // this.$refs.monthSlider.maxValue = 12
    //   }
    // }
  },
  methods: {
          leftLoadMore () {
      let length = 30
      let date = new Date(this.items[0].day)
      let range = this.getDateRange(date, length)
      api.authSubmit('get', '/detection/statisticDate', {
        start: range.start,
        end: range.end
      }).then(success => {
        for (let i = 1; i < length; i++) {
          let day = new Date(date)
          day.setDate(day.getDate() - i)
          let d = day.getDate()
          let available = success.data.filter(e => e.image_date === dayjs(day).format().split('T')[0]).length > 0
          this.items.unshift(this.getItembyDate(day, false, available))
          if (d === 1) {
            this.items.unshift(this.getItembyDate(day, true))
          }
        }
      })
    },
    newAOI(x){
        // console.log('new AOI',x)
        this.aoi = x;
        console.log(x);

        // console.log('New AOI: ' ,this.aoi)
        //console.log('New AOI,')

        // if(this.date.from && this.date.to){ 
        //   // this.changeAOI();
        // }
      },
    ChangeDisplayGroupLegend (group) {
      group.legend.forEach(val => {
        val.hidden = group.checkAll
        this.changeDisplayChart(val)
      })
    },
    refreshChart () {
      this.updateChartData(this.dataTablePlantHealth, this.chartLabel)
    },
    transformData () {
      let newData = []
      
	  // dataTablePlantHealth
    // console.log('TransformData tablePlantHealth:', this.dataTablePlantHealth)
    // console.log('TransformData tableP:', this.labels)

    // console.log('Clicked Period: ', this.chartPeriod)
    let dataNo = this.labels.findIndex(data => data === this.chartPeriod)


      try {
          this.dataTablePlantHealth.forEach(val => {
            // console.log('Each Iteration: ', val);
            // console.log('Label: ', val.label ,' Data: ', val.data[dataNo], ' Colour: ', val.backgroundColor)
            let dataArr = []
            dataArr.push(val.data[dataNo])
           newData.push({
            type: 'bar',
            label: val.label,
            // data: val.data[dataNo],
            data: dataArr,
            barPercentage: 0.8,
            backgroundColor: val.backgroundColor,
            borderColor: '#424242',
            borderWidth: 1,
            stack: 'Plant health'
          })
        })
      } catch (error) {
        // console.log('Error from TransformData() ', error)
      }
      // console.log('ChartData: ',  newData )

      return newData

	  /**/
    },
    changeDisplayChart (legend) {
      this.myChart.setDatasetVisibility(legend.datasetIndex, legend.hidden);
      this.myChart.update()
    },
    updateChartData (newData, labels) {
      // console.log('Returned Datasets: ', newData)
      // console.log('Returned Labels: ', labels)
      this.myChart.data.datasets = newData
      this.myChart.data.labels = labels
      // console.log('Refresh Chart Click')
      this.myChart.update()
    },
    displayChart () {
      if (!this.greenCoverData) {
        console.log('Get to first Return? ')
      return
      }
      this.transformData()
      console.log('Error after transform Data?')
      const data = {
        // labels: this.convertMonth(this.greenCoverData.labels),
        labels: '["<  1","1 - 2","3 - 4","5 - 9","10 - 14","15 - 19","20 - 24","25 - 29","> - 29"]',
        // datasets: this.chartData
        datasets: [{
			label: 'Employee',
			backgroundColor: "#caf270",
			data: [12, 59, 5, 56, 58,12, 59, 87, 45],
		}, {
			label: 'Engineer',
			backgroundColor: "#45c490",
			data: [12, 59, 5, 56, 58,12, 59, 85, 23],
		}, {
			label: 'Government',
			backgroundColor: "#008d93",
			data: [12, 59, 5, 56, 58,12, 59, 65, 51],
		}, {
			label: 'Political parties',
			backgroundColor: "#2e5468",
			data: [12, 59, 5, 56, 58, 12, 59, 12, 74],
		}]
      }
      console.log('Error after chart data?')
      let ctx = document.getElementById('myChart')
      const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate: (chart, args, options) => {
          this.chartLegend = chart.options.plugins.legend.labels.generateLabels(chart);
        }
      }
      console.log('Error after this.myChart?')
      console.log('ctx: ', ctx);
      console.log('Undefinied?: ' ,this.myChart);
      let tempChart = ''
      tempChart = new Chart(ctx, {
        data: data,
        options: {
          plugins: {
            htmlLegend: {
              containerID: 'legend-container',
            },
            legend: {
              display: false,
              position: window.innerHeight > 850 ? 'right' : 'top'
            }
          },
          pointBorderColor: '#893FF2',
          pointBorderWidth: 3,
          pointBackgroundColor: '#fff',
          onClick: (c,i) => {
            let e = i[0]
            if (!e) return
            console.log('Error after myChart clicked?')
            let newData = []
            // newData.push({
            //   type: 'line',
            //   label:  this.greenCoverData.area_change.label,
            //   data:  [this.greenCoverData.area_change.data[e.index]],
            //   fill: true,
            //   pointRadius: 7,
            //   pointHoverRadius: 7,
            //   backgroundColor: 'rgba(137,63,242,0.35)',
            //   stack: 'Green area change'
            // })
            // newData.push({
            //   type: 'bar',
            //   label:  this.greenCoverData.green_area.label,
            //   data:  [this.greenCoverData.green_area.data[e.index]],
            //   barPercentage: 0.8,
            //   backgroundColor:  'rgb(54, 162, 235)',
            //   borderColor:  'rgb(54, 162, 235)',
            //   borderWidth: 1,

            //   stack: 'Green area'
            // })
            // console.log('Error Just Below? 1')
            this.dataTablePlantHealth.forEach(val => {
              newData.push({
                type: 'bar',
                label: val.label,
                data: [val.data[e.index]],
                barPercentage: 0.8,
                backgroundColor: val.color,
                borderColor: '#f76700',
                borderWidth: 1,
                stack: 'Plant health'
              })
            })
            // console.log('Error Just Below? 2')
            // this.greenCoverData.green_density.forEach(val => {
            //   newData.push({
            //     type: 'bar',
            //     label: val.label,
            //     data: [val.data[e.index]],
            //     barPercentage: 0.8,
            //     backgroundColor: val.color,
            //     borderColor: '#434cf6',
            //     borderWidth: 1,
            //     stack: 'Density'
            //   })
            // })
            
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false
              }
            },
            y: {
              title: {
                text: 'Plant Health AREA SQKM',
                display: true
              },
              stacked: true,
              grid: {
                display: true
              }
            }
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          }
        },
        plugins: [htmlLegendPlugin]
      })
      this.myChart = tempChart
      // console.log('Error after myChart initialization? ')
    },
    // async getAOI () {
    //   try {
    //     this.loading = true
    //     const res = await getAOI({source: this.dataSource})
    //     this.aois = res.data.data
    //     if (!this.aoi) {
    //       let sing = this.aois.find(val => val.name === 'Singapore')
    //       if (sing) this.aoi = sing
    //       else this.aoi = this.aois[1]
    //     }
    //     //this.$refs.mapView.addSingaporeBoundary(this.aoi.geometry)
    //   } catch (e) {
    //     console.log(e)
    //   } finally {
    //     this.loading = false
    //   }
    // },
    changeAOI (item) {
      // console.log('From Main Menu', item)

      // console.log('To get All AOI: ',item);
      this.aoi = item
      // console.log('Item: ', this.aoi)
	  
	    // var polygon = turf.polygon(this.aoi.geometry.features[0].geometry.coordinates)

      if (item.bbox){
        var polygon = turf.polygon(this.aoi.geometry.coordinates[0])
	      this.aoi_area = (area(polygon)/1000000.0).toFixed(2)
      }



      // let newClass = new overviewAPI();
      // console.log(newClass);
      // console.dir(overviewAPI);
      
      // let resAPI = overviewAPI.getDetails(strAOI, '12-2021', '01-2022')

      // console.log('From Main: ', resAPI);
      // this.$refs.mapView.addSingaporeBoundary(this.aoi.geometry)

      
      // console.log('Calling API')




      // console.log()
      // let localMth = this.compareMonth.value + '-' + this.currentPeriod.year


      // console.log('localMth: ', this.compareMonth);

      // let globalMth = this.month.toString() + '-' + this.currentPeriod.year
      // console.log('AOI: ', strAOI, ' Global Mth: ', globalMth, ' LocalMth: ', localMth);
      
      // let GetLayersReq = await overviewAPI.getDetails('02-2022', '11-2021', strAOI);
      


      // let GetLayersReq = await axios.get('https://imoa.eofactory.ai:3443/api/v1/layers', {
      //   headers: {
      //     'X-Authorization': 'H50jsyR7JpDwj7sVoU86hkoaN1Pr6osF2xyJWaKTNnqK27jx5Oft1NLRsHB7DCgt',
      //         'Accept': 'application/json'
      //     },
      //     params:{
      //       'from_date': '11-2021',
      //         'to_date':  newLocalMth,
      //         'aoi_ids': strAOI
      //     }
      // })
      // .then((res)=> {
      //   //   console.log('All Layers: ',res.data)
      //   return res.data
      // })
      // .catch((error) => {
      //   // console.error(error)
      //   return error
      //   })

        // To image to base map, create a new variable then add res.data & this.aoi
        // this.aoi.tile_url = GetLayersReq.data[0].mosaic_image
        // this.aoi.data_type = "raster_image"
        // this.aoi.id = Math.floor(Math.random() * 103487620).toString(),
        // this.aoi.min_zoom = 14,
        // this.aoi.max_zoom =  18,
        // this.aoi.type = "mosaic"
        // this.$refs.mapView.createImageLayer(this.aoi, true)
        // this.aoi.plant_health_image = GetLayersReq.data[0].plant_health_image
        // console.log(this.aoi)
        
        // File_get_contents(Https://Geomineraba.Eofactory.Ai:3443/Api/V1/Imageries/Statistics?Month=11&Amp;Compare_month=10&Amp;Year=2021&Amp;Source=Planetscope&Amp;Overview_type=Overall_green_cover&Amp;Aoi_id=0875d42d-266c-4101-A5bf-7e0bc1ac94be): Failed To Open Stream: HTTP Request Failed! HTTP/1.1 500 Internal Server Error




        // # Error with adding to mapView, probably because response data doesn't have coordinate, bbox, id & other fields
        // this.$refs.mapView.createImageLayer(GetLayersReq.data[0].mosaic_image, true)
        // this.$refs.mapView.createImageLayer(GetLayersReq.data[0].plant_health_image, true)
        // sources.undefined.tiles[0]: string expected, undefined found
        // sources.undefined.bounds: array expected, undefined found
        // layers.undefined: missing required property "source"
        // layers.undefined.id: string expected, undefined found
        // layers.undefined.source: string expected, undefined found
        // layers.undefined: missing required property "id"
        // Error in v-on handler (Promise/async): "TypeError: Cannot read properties of undefined (reading 'getNorthWest')"
        // Cannot read properties of undefined (reading 'getNorthWest')

        //         {
        //     "id": "0875d42d-266c-4101-a5bf-7e0bc1ac94be",
        //     "name": "2021_09_monthly_image_mosaic",
        //     "tile_url": "https://geomineraba.eofactory.ai:3443/tileapi/v2/tile/2021/09/0875d42d266c4101a5bf7e0bc1ac94be/{z}/{x}/{y}/multiband?red=YjM%3D&green=YjI%3D&blue=YjE%3D&color_map=&nodata=0&method=no&minmax=",
        //     "min_zoom": 14,
        //     "max_zoom": 18,
        //     "list_values": [],
        //     "bbox": [
        //         101.72796587,
        //         -0.850199438,
        //         101.738654064,
        //         -0.833477149
        //     ],
        //     "type": "mosaic",
        //     "month": "09",
        //     "year": 2021,
        //     "data_type": "raster_image",
        //     "aoi_id": 95
        // }
       this.getData()
    },
    ChangeTab(x){
      // this.dropdown = true
      this.dropdown = x
      // this.dropdown = x
    },
    chgLocalMonth(date) {
      // console.log('Change Local Date: ',date)
      this.localMonth = date //Cannot convert to backend comp date. Prop localMth connected to DatePicker
      // Have to invert YYYY-MM to MM-YYYY
      
      this.getData()
    },
    comparePeriod(){
      // console.log('Global: ', global)
      // console.log('Local: ', local);

      let global_Mth = Math.floor(this.month)
      let global_year = Math.floor(this.currentPeriod.year)

      let local_Mth = Math.floor(this.localMonth.substring(7,5))
      let local_year = Math.floor(this.localMonth.substring(0,4))

      // console.log('Local: '+  local_Mth + '-',local_year)
      // console.log('Global: '+  global_Mth + '-',global_year)

      if(local_year >= global_year && local_Mth > global_Mth){
        // Return Local as To
        // console.log('Return local as To')
        if (local_Mth < 10){
          local_Mth = '0' + local_Mth.toString()
        }
        else{
          local_Mth = local_Mth.toString()
        }
        if (global_Mth < 10){
          global_Mth = '0' + global_Mth.toString()
        }
        else{
          global_Mth = global_Mth.toString()
        }       

        return {from_date: `${global_Mth}-${global_year}`, to_date: `${local_Mth}-${local_year}`}
      }
      else {
        // Return Global as To
        console.log('Return Global as To')
        if (local_Mth < 10){
          local_Mth = '0' + local_Mth.toString()
        }
        else{
          local_Mth = local_Mth.toString()
        }
        if (global_Mth < 10){
          global_Mth = '0' + global_Mth.toString()
        }
        else{
          global_Mth = global_Mth.toString()
        }     
        return {from_date: `${local_Mth}-${local_year}`, to_date: `${global_Mth}-${global_year}`}
      }

    },
    timelineMonth(item){
      // console.log('Coming from Timeline: ', item)
//       {
//  @   "label": "Jul-2022",
//  @   "month": "Jul",
//  @   "year": "7-2022",
//  @   "divyear": false,
//  @   "day": "7-2022",
//  @   "date": 7,
//  @   "available": true
// }

// var last4 = id.substr(id.length - 4);
      let year = item.year

      this.currentPeriod.year = year.substr(year.length - 4)
      this.month = item.date
      this.getData();

    },
    async getData () { 

      
      this.loading = true
      try {
        let strAOI = ''
        // let periodDate = this.comparePeriod();
        let globalMth, newLocalMth = ''

        // let from_date = periodDate.from_date
        // let to_date = periodDate.to_date
        // console.log('from_date: ', from_date)
        // console.log('to_date: ', to_date)
      let global_Mth = Math.floor(this.month)
      let global_year = Math.floor(this.currentPeriod.year)

      let local_Mth = Math.floor(this.localMonth.substring(7,5))
      let local_year = Math.floor(this.localMonth.substring(0,4))

      let G_Mth = ''
      let G_Year = parseInt(this.currentPeriod.year)
      if (this.month < 10){
        G_Mth = '0' + this.month.toString()
      }
      else {
        G_Mth = this.month.toString()
      }

        if (this.month < 10){
          globalMth = '0' + this.month.toString() + '-' + this.currentPeriod.year.toString()
        }
        else {
          globalMth = this.month.toString() + '-' + this.currentPeriod.year.toString()
        }
        newLocalMth = this.localMonth.substring(7,5) + '-' + this.localMonth.substring(0,4)      

        let checker = this.aoi.name.substring(4,6)
        let res = '';
        
        if(checker !== 'AO'){
          // & Single AOI
          strAOI = this.aoi.name.substring(4,6)
                     
          let LocalDate = new Date(local_year, parseInt(local_Mth) - 1, 15)
          let GlobalDate = new Date(global_year, parseInt(global_Mth) - 1, 15)
          if(LocalDate >= GlobalDate){
            // res = await overviewAPI.getDetails(strAOI, "12-2021", newLocalMth);
            res = await overviewAPI.getDetails(strAOI, "12-2021", "12-2022");
            // console.log('Local Higher than Global: ',res)
          }
          else{
            // res = await overviewAPI.getDetails(strAOI, "12-2021", globalMth );
            res = await overviewAPI.getDetails(strAOI, "12-2021", "12-2022" );
            // console.log('Global Higher than Local: ',res)
          }
          
          
          //& Define Variable for Res Data
          //& Create forEach loop over each row of statistic object to get according to ha or sqkm
          //& After conversion, create a new variable to add, division of statisitc area by total area of AOI
          //& Passed new variable to tab.vue to populate bar chart
          //& Create another variable for comparison division of from & to. Just dividing it & passing the new var to the compare section of tab.vue

          //& myChart = ID of canvas projecting chart

          //& For Individual AOI
              let allData = res.data

              // console.log('Individual AOI (ALLDATA):',allData);
          
              this.GlobalAndLocalBreakdown(res)
              this.AreaChanges()
              // & Unlink Chart
              this.AddToChart(allData, res)

              //& To Add Image to Map

              let mapArr = allData.filter(el => el.month === G_Mth && el.year === G_Year)
              // console.log('Single AOI: ', mapArr)
              let mapImageData = mapArr[0]
              
              if (this.$refs.mapView.layers.length > 0 ){
                  // this.$refs.mapView.removeLayer() //Single Layers
                  this.$refs.mapView.removeLayer()
                  this.AddToMap(mapImageData)
              }
              else {
                  // console.log('No Layers');
                  this.AddToMap(mapImageData)
                  // console.log('Actual Layer on Map: ', this.$refs.mapView.layers);
              }
            // ! NEED TO PASS LAYERS 
              if (this.aoi.geometry){
                this.zoomToArea()
              }
          this.loading = false;
        }
        else{
          // & All AOI
          strAOI = '01,02,03,04,05,06,07,08,09,10,11,12,13,14,15,16,17,18,19,20,21,22,23'
          // console.log('ALL AOI Launched:')
          this.aoi_area = 1267.42711894030003
          let LocalDate = new Date(local_year, parseInt(local_Mth) - 1, 15)
          let GlobalDate = new Date(global_year, parseInt(global_Mth) - 1, 15)
          if(LocalDate >= GlobalDate){
            res = await overviewAPI.getDetails(strAOI, globalMth, newLocalMth);
            // console.log('Local Higher than Global: ',res)
          }
          else{
            res = await overviewAPI.getDetails(strAOI, newLocalMth, globalMth );
            // console.log('Global Higher than Local: ',res)
          } 

          


          // console.log('Res: ', res)

          let allData = res.data

          let G_Mth = ''
          let G_Year = parseInt(this.currentPeriod.year)

          if (this.month < 10){
            G_Mth = '0' + this.month.toString()
            //& FOR TESTING ONLY
            // G_Mth = '0' + (this.month + 1) .toString()
          }
          else {
            G_Mth = this.month.toString()
            //& FOR TESTING ONLY
            // G_Mth = (this.month + 1).toString()
          }
          let Global = {
            month: G_Mth,
            year: G_Year,
            statistic: [
              {index:'Stressed', area: 0},
              {index:'Slightly Stressed', area: 0},
              {index:'Moderate', area: 0},
              {index:'Healthy', area: 0},
              {index:'Very healthy', area: 0},
            ]
          }

          let L_Mth = this.localMonth.substring(7,5)
          let L_Year = parseInt(this.localMonth.substring(0,4))
          let Local = {
            month: L_Mth,
            year: L_Year,
            statistic: [
              {index:'Stressed', area: 0},
              {index:'Slightly Stressed', area: 0},
              {index:'Moderate', area: 0},
              {index:'Healthy', area: 0},
              {index:'Very healthy', area: 0},
            ]
          }

          let GlobalArr = allData.filter(el => el.month === G_Mth && el.year === G_Year)
          let LocalArr = allData.filter(el => el.month === L_Mth && el.year === L_Year)

          GlobalArr.forEach((item)=>{
              Global.statistic[0].area = Global.statistic[0].area + item.statistic[0].area
              Global.statistic[1].area = Global.statistic[1].area + item.statistic[1].area
              Global.statistic[2].area = Global.statistic[2].area + item.statistic[2].area
              Global.statistic[3].area = Global.statistic[3].area + item.statistic[3].area
              Global.statistic[4].area = Global.statistic[4].area + item.statistic[4].area
          })

          LocalArr.forEach((item)=>{
              Local.statistic[0].area = Local.statistic[0].area + item.statistic[0].area
              Local.statistic[1].area = Local.statistic[1].area + item.statistic[1].area
              Local.statistic[2].area = Local.statistic[2].area + item.statistic[2].area
              Local.statistic[3].area = Local.statistic[3].area + item.statistic[3].area
              Local.statistic[4].area = Local.statistic[4].area + item.statistic[4].area
          })

          let allArr = {
            data: []
          }
          allArr.data.push(Global,Local)

          // this.AddToChart(allArr.data, allArr)
          // console.log('Local Stats: ', Local.statisitc[0]);
          // console.log('Local Stats Area: ', Local.statisitc[0].area);
          // // allData[0].statistic[0].area
          // // console.log(allArr);
          // console.log('Global All Arr Stats: ',allArr.data[0].statisitc)

          // console.log('Global All Arr Stats Area: ',allArr.data[0].statisitc[0].area)
          this.GlobalAndLocalBreakdown(allArr)
          this.AreaChanges()
          // & Unlick chart
          // this.AddToChart(allArr.data, allArr)

          // GlobalArr
          // LocalArr

          
          
          // console.log('Before Map: ', allData)
          let mapImageData = allData[0]
          // console.log('Before All Adding mapImageData', mapImageData)
          // let plantTile = {
          // name: 'Plant Health',
          // tile: [],
          // bbox: this.projectBbox,
          // month: GlobalArr[0].month,
          // year: GlobalArr[0].year
          // }
          // let mosiacTile = {
          //   name: 'Mosiac',
          //   tile: [],
          //   bbox: this.projectBbox,
          //   month: GlobalArr[0].month,
          //   year: GlobalArr[0].year
          // }

          // GlobalArr.forEach((item)  => {
          //   if (item.mosaic_image){
          //     mosiacTile.tile.push(item.mosaic_image)
          //   }
          //   if(item.plant_health_image){
          //     plantTile.tile.push(item.plant_health_image)
          //   }
          // })
          
          //Add to Map
          if (this.$refs.mapView.layers.length > 0 ){
              //& clear this.layer here = ''
                this.$refs.mapView.removeLayer()
                  // this.$refs.mapView.addAllAoi()
                  this.$refs.mapView.addAOI(allAOI)
            }
            else {
              //& clear this.layer here = ''
                GlobalArr.forEach((item)=> {
                  this.$refs.mapView.addAOI(allAOI)
                })
          }
          // 114.3353 -3.0775 8.18
          // this.$refs.mapView.flyTo({
          //   center: [114.3353, -3.0775],
          //   zoom: 8.18
          // })
          console.log(this.$refs.mapView)
          // this.$refs.mapView.zoom = 8.18
          this.$refs.mapView.submitZoom(this.projectBbox)
        }
       
      } 
      catch (e) {
        this.$refs.mapView.removeLayer()
        console.log(e.message)
        // this.loading = false
      } 
      finally {
        this.loading = false
      }

    },
    AddToChart(allData,res){
      this.loading = true
      this.dataTablePlantHealth = []
      let chartLabel = []
        // console.log('Chart: ',allData);

        // console.log('allData: ', allData)
        allData.forEach((i)=> {
            let index = parseInt(i.month) - 1
            let mth = this.monthLabels[index]
            chartLabel.push( mth + ' ' + i.year.toString())
        })
        this.chartLabel = chartLabel
        let stress = {
          label : '',
          backgroundColor : '', 
          data: []
        }
        let Sstress = {
          label : '',
          backgroundColor : '', 
          data: []
        }
        let moderate = {
          label : '',
          backgroundColor : '', 
          data: []
        }
        let health = {
          label : '',
          backgroundColor : '', 
          data: []
        }
        let Vhealthy = {
          label : '',
          backgroundColor : '', 
          data: []
        }
        // console.log('Graph Res: ', res);

          res.data.forEach((e) => {
          // let item = {}
          // item.month = e.month
          // item.year = e.year
            e.statistic.forEach((i) => {
              // console.log('I=', i);
              if(i.index === 'Stressed'){
                stress.label = i.index
                stress.backgroundColor = '#FF0000'
                stress.data.push(i.area / 1000000)
              }
              if (i.index === 'Slightly Stressed'){
                Sstress.data.push(i.area / 1000000)
                Sstress.label = i.index
                Sstress.backgroundColor = '#ED7D31'
              }
              if (i.index === 'Moderate'){
                moderate.data.push(i.area / 1000000)
                moderate.label = i.index
                moderate.backgroundColor = '#B8BB23'
              }
              if (i.index === 'Healthy'){
                health.data.push(i.area / 1000000)
                health.label = i.index
                health.backgroundColor = '#649F25'
              }
              if (i.index === 'Very healthy'){
                Vhealthy.data.push(i.area / 1000000)
                Vhealthy.label = i.index
                Vhealthy.backgroundColor = '#375623'
              }
            })
        })

        this.dataTablePlantHealth.push(stress, Sstress, moderate, health, Vhealthy)

        this.labels = []//Empty label array
        let ctx = document.getElementById('myChart');
        const htmlLegendPlugin = {
          id: 'htmlLegend',
          afterUpdate: (chart, args, options) => {
            this.chartLegend = chart.options.plugins.legend.labels.generateLabels(chart);
          }
        }

        this.labels = chartLabel
        // var destroyChart = new Chart(ctx)
        if(this.myChart){
          this.myChart.destroy();
        }
        this.myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: chartLabel,
          datasets: this.dataTablePlantHealth,
        },
        options: {
          plugins: {
            htmlLegend: {
              containerID: 'legend-container',
            },
            legend: {
              display: false,
              position: window.innerHeight > 850 ? 'right' : 'top'
            }
          },
          stack: 'Plant Health',
          pointBorderColor: '#893FF2',
          pointBorderWidth: 3,
          pointBackgroundColor: '#fff',
          onClick: (c,i) => {
            let e = i[0]
            if (!e) return
            // console.log('On CLick: ', this.labels[e.index])
            this.chartPeriod = this.labels[e.index]
            let DateArr =  []
            DateArr.push(this.chartPeriod)


            this.updateChartData(this.transformData(), DateArr)
            
            // let newData = []
            // this.dataTablePlantHealth.forEach(val => {
            //           newData.push({
            //           type: 'bar',
            //           label: val.label,
            //           data: val.data,
            //           barPercentage: 0.8,
            //           backgroundColor: val.color,
            //           borderColor: '#f76700',
            //           borderWidth: 1,
            //           stack: 'Plant health'
            //         })
            // })
            // return newData
          },
          tooltips: {
            displayColors: true,
            callbacks:{
              mode: 'x',
            },
          },
           scales: {
            x: {
              stacked: true,
              grid: {
                display: false
              }
            },
            y: {
              title: {
                text: 'Plant Health Area (sqkm)',
                display: true
              },
              stacked: true,
              grid: {
                display: true
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
                  tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          }
        },
        //& Graph Stack on the right side(Legends)
        plugins: [htmlLegendPlugin]
        
        });
        // console.log('Graph Chart Data: ',this.myChart);
    },
    AreaChanges(){
        //& Compare Changes of Increment/Decrement
        //& To Get Changes in sqkm
        this.summary.changes.stressed_sqkm = (this.summary.PlantHealth.Right.stressed_sqkm - this.summary.PlantHealth.Left.stressed_sqkm).toFixed(2)
        this.summary.changes.slightstress_sqkm = (this.summary.PlantHealth.Right.slightstress_sqkm - this.summary.PlantHealth.Left.slightstress_sqkm).toFixed(2)
        this.summary.changes.Moderate_sqkm = (this.summary.PlantHealth.Right.Moderate_sqkm - this.summary.PlantHealth.Left.Moderate_sqkm).toFixed(2)
        this.summary.changes.Health_sqkm = (this.summary.PlantHealth.Right.Health_sqkm - this.summary.PlantHealth.Left.Health_sqkm).toFixed(2)
        this.summary.changes.VeryHealthy_sqkm = (this.summary.PlantHealth.Right.VeryHealthy_sqkm - this.summary.PlantHealth.Left.VeryHealthy_sqkm).toFixed(2)

        //& To get changes in %
        this.summary.changes.stressed = (this.summary.PlantHealth.Right.stressed_sqkm / this.summary.PlantHealth.Left.stressed_sqkm ).toFixed(2)
        this.summary.changes.stressed = (1 - this.summary.changes.stressed) * 100
        if(this.summary.changes.stressed < 0){
          this.summary.changes.stressed = Math.abs(this.summary.changes.stressed).toFixed(2)
        }
        else{
          this.summary.changes.stressed = -Math.abs(this.summary.changes.stressed).toFixed(2)
        }
        
        this.summary.changes.slightstress = (this.summary.PlantHealth.Right.slightstress_sqkm / this.summary.PlantHealth.Left.slightstress_sqkm ).toFixed(2)
                this.summary.changes.slightstress = (1 - this.summary.changes.slightstress) * 100
        if(this.summary.changes.slightstress < 0){
          this.summary.changes.slightstress = Math.abs(this.summary.changes.slightstress).toFixed(2)
        }
        else{
          this.summary.changes.slightstress = -Math.abs(this.summary.changes.slightstress).toFixed(2)
        }
        
        this.summary.changes.Moderate = (this.summary.PlantHealth.Right.Moderate_sqkm / this.summary.PlantHealth.Left.Moderate_sqkm ).toFixed(2)
                this.summary.changes.Moderate = (1 - this.summary.changes.Moderate) * 100
        if(this.summary.changes.Moderate < 0){
          this.summary.changes.Moderate= Math.abs(this.summary.changes.Moderate).toFixed(2)
        }
        else{
          this.summary.changes.Moderate = -Math.abs(this.summary.changes.Moderate).toFixed(2)
        }
        
        this.summary.changes.Health = ( this.summary.PlantHealth.Right.Health_sqkm / this.summary.PlantHealth.Left.Health_sqkm ).toFixed(2)
                this.summary.changes.Health = (1 - this.summary.changes.Health) * 100
        if(this.summary.changes.Health < 0){
          this.summary.changes.Health = Math.abs(this.summary.changes.Health).toFixed(2)
        }
        else{
          this.summary.changes.Health = -Math.abs(this.summary.changes.Health).toFixed(2)
        }
        
        this.summary.changes.VeryHealthy = ( this.summary.PlantHealth.Right.VeryHealthy_sqkm / this.summary.PlantHealth.Left.VeryHealthy_sqkm ).toFixed(2)
        this.summary.changes.VeryHealthy = (1 - this.summary.changes.VeryHealthy) * 100
        if(this.summary.changes.stressed < 0){
          this.summary.changes.VeryHealthy = Math.abs(this.summary.changes.VeryHealthy).toFixed(2)
        }
        else{
          this.summary.changes.VeryHealthy = -Math.abs(this.summary.changes.VeryHealthy).toFixed(2)
        }
    },
    GlobalAndLocalBreakdown(res){
        //& Sqkm Only (Left)0000
        let allData = res.data

        let G_Mth = ''
        let G_Year = parseInt(this.currentPeriod.year)
        let L_Mth = this.localMonth.substring(7,5)
        let L_Year = parseInt(this.localMonth.substring(0,4))
        if (this.month < 10){
          G_Mth = '0' + this.month.toString()
        }
        else {
          G_Mth = this.month.toString()
        }

        let leftArr = allData.filter(el => el.month === G_Mth && el.year === G_Year)
        let RightArr = allData.filter(el => el.month === L_Mth && el.year === L_Year)

        // console.log('Global: ', leftArr, ' Local: ', RightArr);
        // console.log('allData: ', allData);

    //  let global_Mth = Math.floor(this.month)
    //   let global_year = Math.floor(this.currentPeriod.year)

    //   let local_Mth = Math.floor(this.localMonth.substring(7,5))
    //   let local_year = Math.floor(this.localMonth.substring(0,4))

    //     if (this.month < 10){
    //       globalMth = '0' + this.month.toString() + '-' + this.currentPeriod.year.toString()
    //     }
    //     else {
    //       globalMth = this.month.toString() + '-' + this.currentPeriod.year.toString()
    //     }
    //     newLocalMth = this.localMonth.substring(7,5) + '-' + this.localMonth.substring(0,4)      

    //     let checker = this.aoi.name.substring(4,6)
    //     let res = '';
        
    //     if(checker !== 'AO'){
    //       strAOI = this.aoi.name.substring(4,6)
            
                     
    //       // console.log('Not ALL: ', strAOI)
    //       if(local_year >= global_year && local_Mth > global_Mth){
    //         res = await overviewAPI.getDetails(strAOI, globalMth, newLocalMth);
    //       }
    //       else{
    //         res = await overviewAPI.getDetails(strAOI, newLocalMth, globalMth );
    //       }
        // allData.filter(el => el.month === G_Mth && el.year === G_Year)

        let arrIndex = (res.data.length - 1)
        this.summary.PlantHealth.Left.stressed_sqkm = (leftArr[0].statistic[0].area / 1000000)
        this.summary.PlantHealth.Left.slightstress_sqkm = (leftArr[0].statistic[1].area / 1000000)
        this.summary.PlantHealth.Left.Moderate_sqkm = (leftArr[0].statistic[2].area / 1000000)
        this.summary.PlantHealth.Left.Health_sqkm = (leftArr[0].statistic[3].area / 1000000)
        this.summary.PlantHealth.Left.VeryHealthy_sqkm = (leftArr[0].statistic[4].area / 1000000) 
        // console.log('Left: ', this.summary.PlantHealth.Left)
        // Bar % (left)
        this.summary.PlantHealth.Left.stressed = (this.summary.PlantHealth.Left.stressed_sqkm / this.aoi_area) * 100
        this.summary.PlantHealth.Left.slightstress = (this.summary.PlantHealth.Left.slightstress_sqkm / this.aoi_area) * 100
        this.summary.PlantHealth.Left.Moderate = (this.summary.PlantHealth.Left.Moderate_sqkm / this.aoi_area) * 100
        this.summary.PlantHealth.Left.Health = (this.summary.PlantHealth.Left.Health_sqkm / this.aoi_area) * 100
        this.summary.PlantHealth.Left.VeryHealthy = (this.summary.PlantHealth.Left.VeryHealthy_sqkm / this.aoi_area) * 100
        // Sqkm Only (Right)
        this.summary.PlantHealth.Right.stressed_sqkm = (RightArr[0].statistic[0].area / 1000000)
        this.summary.PlantHealth.Right.slightstress_sqkm = (RightArr[0].statistic[1].area / 1000000)
        this.summary.PlantHealth.Right.Moderate_sqkm = (RightArr[0].statistic[2].area / 1000000)
        this.summary.PlantHealth.Right.Health_sqkm = (RightArr[0].statistic[3].area / 1000000)
        this.summary.PlantHealth.Right.VeryHealthy_sqkm = (RightArr[0].statistic[4].area / 1000000) 
        // Bar % (Right)
        this.summary.PlantHealth.Right.stressed = (this.summary.PlantHealth.Right.stressed_sqkm / this.aoi_area) * 100
        this.summary.PlantHealth.Right.slightstress = (this.summary.PlantHealth.Right.slightstress_sqkm / this.aoi_area) * 100
        this.summary.PlantHealth.Right.Moderate = (this.summary.PlantHealth.Right.Moderate_sqkm / this.aoi_area) * 100
        this.summary.PlantHealth.Right.Health = (this.summary.PlantHealth.Right.Health_sqkm / this.aoi_area) * 100
        this.summary.PlantHealth.Right.VeryHealthy = (this.summary.PlantHealth.Right.VeryHealthy_sqkm / this.aoi_area) * 100
    },
    AddToMap(mapImageData){

      console.log('mapImageData: ',mapImageData)

        if (mapImageData.mosaic_image) {
          let obj = {}
          obj.id = this.aoi.name + (Math.random() * 100000).toString()
          obj.bbox = this.aoi.bbox
          obj.data_type = 'raster_image'
          obj.min_zoom = 3
          obj.max_zoom = 23
          obj.type = 'mosiac'
          obj.name = this.aoi.name + '_' + mapImageData.month + '_' + mapImageData.year.toString() + "_Mosiac"
          obj.tile_url = mapImageData.mosaic_image
          console.log('Obj: ', obj)
        this.$refs.mapView.createImageLayer(obj, false)
        }

        if (mapImageData.plant_health_image) {
          let obj = {}
          obj.id = this.aoi.name + (Math.random() * 100000).toString()
          obj.bbox = this.aoi.bbox
          obj.data_type = 'raster_image'
          obj.min_zoom = 3
          obj.max_zoom = 23
          obj.type = 'plant health'
          obj.name = this.aoi.name + '_' + mapImageData.month + '_' + mapImageData.year.toString() + "_Plant Health"
          obj.tile_url = mapImageData.plant_health_image
          this.$refs.mapView.createImageLayer(obj , true)
        }

        // console.log('All Layers:' ,this.$refs.mapView.layers);
        this.mapLayers = this.$refs.mapView.layers
//         bbox = left,bottom,right,top
// bbox = min Longitude , min Latitude , max Longitude , max Latitude 
        // console.log('DataSource', this.dataSource);
    },
    AllToMap(mapImageData){

      console.log('mapImageData: ',mapImageData)
      // var itemBbox = [];
      // let GlobalArr = allData.filter(el => el.month === G_Mth && el.year === G_Year)
      // let arr = allAOI.features
      // console.log('Arr: ', arr);
      // console.log('AOI: ', mapImageData)
      // let id = 'AOI ' + mapImageData.aoi_id.toString()
      // itemBbox = arr.filter(el => el.name === id)
      // console.log('Item: ',itemBbox)

        if (mapImageData.name = 'Mosiac') {
          let obj = {}
          obj.id = mapImageData.name + (Math.random() * 100000).toString()
          obj.bbox = mapImageData.bbox.coordinates[0]
          obj.data_type = 'raster_image'
          obj.min_zoom = 3
          obj.max_zoom = 23
          obj.type = 'mosiac'
          obj.name = mapImageData.name + '_' + mapImageData.month + '_' + mapImageData.year.toString()
          obj.tile_url = mapImageData.mosaic_image
          console.log('Obj: ', obj)
          this.$refs.mapView.createImageLayer(obj , true)
        }

        if (mapImageData.name = 'Plant Health') {
          let obj = {}
          obj.id = mapImageData.name + (Math.random() * 100000).toString()
          obj.bbox = mapImageData.bbox.coordinates[0]
          obj.data_type = 'raster_image'
          obj.min_zoom = 3
          obj.max_zoom = 23
          obj.type = 'plant health'
          obj.name = mapImageData.name+ '_' + mapImageData.month + '_' + mapImageData.year.toString()
          obj.tile_url = mapImageData.plant_health_image
          this.$refs.mapView.createImageLayer(obj , true)
        }

        this.mapLayers = this.$refs.mapView.layers
    },
    zoomToArea() {
      this.$refs.mapView.submitZoom(bbox(this.aoi.geometry))
    },
    convertMonth (monthArray) {
      return MonthTransform.transformMonthTextArray(monthArray)
    },
    transformNumber (number) {
      return numberFormat.numberWithCommas(number)
    }
  }
}
</script>

<style scoped>

.PanelBox{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 1px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 11px;
}

.LP-Background{
  background: linear-gradient(180deg, rgba(205, 194, 219, 0.18) 0%, rgba(218, 185, 215, 0.18) 40.1%, rgba(209, 181, 207, 0.18) 40.11%, rgba(226, 213, 227, 0.18) 78.83%, rgba(231, 227, 233, 0.18) 100%);
  box-shadow: 1px 1px 6px 1px rgba(184, 181, 189, 0.32);
}

/deep/
.filter-select .v-input__slot {
  min-height: 0 !important;
}
/deep/
.filter-select .v-input__append-inner {
  margin-top: 2px !important;
}
/deep/
.month-picker .v-input__slot {
  min-height: 0 !important;
}
/deep/
.month-picker .v-input__prepend-inner {
  margin-top: 4px !important;
}
/deep/
.legend-panel .v-expansion-panel-content__wrap {
  padding-left: 10px !important;
  padding-right: 0px !important;
  padding-bottom: 4px !important;
  padding-top: 8px !important;
}
/deep/
.legend-panel .v-expansion-panel-header {
  height: 32px !important;
  font-size: 13px !important;
  min-height: 0 !important;
  padding: 10px !important;
  border: 1px solid rgba(137, 63, 242, 0.2);
  box-sizing: border-box;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px !important;
}
/deep/
.legend-panel .v-expansion-panel-header--active {
  height: 32px !important;
  font-size: 13px !important;
  min-height: 0 !important;
  padding: 10px !important;
  border-radius: 10px !important;
}
/deep/
.v-select__selection {
  color: #893FF2 !important;
  text-transform: uppercase;
  font-weight: 500;
}
/deep/
.select-input .v-input__control {
  box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.2), inset 0px 0px 4px rgba(137, 63, 242, 0.33);
}
/deep/
.small-radio .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: currentColor;
  transform: scale(0.8);
  transition: none;
}
</style>
