<template>
  <subMenu class="px-0" style="width: 100%" 
    :name='selection' 
    :menu-items='fileMenuItems' 
    @itemClick='onMenuItemClick' 
    color="cyan" 
    :is-offset-x="true" 
    :is-offset-y="false"
    >
    <v-icon class="justify-content-end">mdi-menu-right</v-icon>
  </subMenu>
	
</template>

<script>
import subMenu  from './subMenu'
import aois_geojson from '@/assets/AOI_Planet.json'

export default {
  name: 'dropdownBtn',
  components: { subMenu },
    data: () => ({
    fileMenuItems: [
      {
        name: "All AOI",
      }
    ],
    aois: [],
    mineral: [],
    batu: [],
    filtered: [],
    aoi_Hash: {},
    provinces: [],
    mines: [],
    specificNav: [],
    selection: 'All AOI'

  }),
  beforeMount() {
    // console.log('Testing before Mount')

    // console.log('New:', aois_geojson)
     aois_geojson.features.forEach((f) => {
          var x1 = 999999999
          var x2 = -999999999
          var y1 = 999999999
          var y2 = -999999999
          var c = f.geometry.coordinates[0][0]
          // console.log('Coor:', f.geometry.coordinates);
          for (var a = 0; a < c.length; a++ ) {
            if (c[a][0] < x1)
              x1 = c[a][0]
            if (c[a][0] > x2)
              x2 = c[a][0]	
            if (c[a][1] < y1)
              y1 = c[a][1]
            if (c[a][1] > y2)
              y2 = c[a][1]		
          }
            f.bbox =  [x1,y1,x2,y2]
            // console.log('Bbox: ', f.bbox);
      })
      this.aois = aois_geojson.features;
      // console.log('AOIS: ', this.aois)

      // console.log('Run Test')
      this.data = 'pronvince'
      this.aoi_Hash = {}
      this.provinces = []
      this.mines = []
      var aoi_Hash = {}
      var provinces = []

      var province_hash = {}
      var specificContainer = { menu: [] }

      // Getting Provinces
      this.aois.forEach((f) => {
              aoi_Hash[f.properties.name] = f;
              // console.log()
              // console.log('aoi_hash - Lvl 1', f);
                  if (!province_hash[f.properties.District])
                  {
                    // console.log('prov_hash - Lvl 2', province_hash[f.properties.province])
                    // console.log()
                      province_hash[f.properties.District] = 1
                      provinces.push(f.properties.District)
                      // console.log('Provinces', this.provinces)
                  }
              
      })

      // Specific AOI
      for (let i = 0; i < provinces.length; i++){
        //Create name of object
            let newObj = {}
            newObj.name = provinces[i]
            newObj.menu = []
                //Loop current list of array
                let filterArr = this.aois.filter(el => {
                //Check condition to return
                // console.log('Inner Filter',el)
                    if(el.properties.District === provinces[i]){
                        el.name = el.properties.Name
                        return el.name
                      }  
                })
            newObj.menu = filterArr
            specificContainer.menu.push(newObj)
      }
      // console.log(specificContainer)      
      specificContainer.menu.forEach(item => {
        this.fileMenuItems.push(JSON.parse(JSON.stringify(item)))
      });
      // console.log(this.fileMenuItems)
      // this.fileMenuItems = JSON.parse(JSON.stringify(specificContainer.menu))

  },
 methods: {
    onMenuItemClick(item) {
      // console.log('Emited to Parent')
      // console.log(`From SubMenu`,item);
      // console.log()
      // if (item.action) {
      //   item.action();
      // }

      // console.log('From Child', item)
      // console.log('Base Selection: ', this.selection);
      this.selection = item.name
      // console.log('Selection: ', this.selection);
      // console.log('Item After Selection To Emit: ', item)

      if(this.selection === 'All AOI'){
        var allAOI = {}
        allAOI.name = 'All AOI'
        this.$emit("aoiSelected", allAOI);
      }
      else{
        this.$emit("aoiSelected", item);
      }
      // console.log('Select: ', this.selection)

      // console.log('New Selection: ', this.selection);
      // console.log('After ALL Initialization: ', item);

    },
  //   test(){
  //     aois_geojson.features.forEach((f) => {
  //         var x1 = 999999999
  //         var x2 = -999999999
  //         var y1 = 999999999
  //         var y2 = -999999999
  //         var c = f.geometry.coordinates[0]

  //         for (var a = 0; a < c.length; a++ ) {
  //           if (c[a][0] < x1)
  //             x1 = c[a][0]
  //           if (c[a][0] > x2)
  //             x2 = c[a][0]	
  //           if (c[a][1] < y1)
  //             y1 = c[a][1]
  //           if (c[a][1] > y2)
  //             y2 = c[a][1]		
  //         }
  //           f.bbox =  [x1,y1,x2,y2]
  //     })

  //     this.aois = aois_geojson.features;

  //     this.data = 'pronvince'
  //     this.aoi_Hash = {}
  //     this.provinces = []
  //     this.mines = []
  //     var aoi_Hash = {}
  //     var provinces = []
  //     var mines = []

  //     var province_hash = {}
  //     var specificContainer= { name: 'Specific AOI', menu: []}
      
  //     // Getting Provinces
  //     this.aois.forEach((f) => {
  //             aoi_Hash[f.properties.name] = f;
  //             // console.log()
  //             // console.log('aoi_hash - Lvl 1', f);
  //                 if (!province_hash[f.properties.District])
  //                 {
  //                   // console.log('prov_hash - Lvl 2', province_hash[f.properties.province])
  //                   // console.log()
  //                     province_hash[f.properties.District] = 1
  //                     provinces.push(f.properties.District)
  //                     // console.log('Provinces', this.provinces)
  //                 }
  //     })
  //     console.log('Next Line')
  //     console.log('Prov: ', provinces)

  //     // Getting Mine
  //     this.aois.forEach((f) => {
  //             aoi_Hash[f.properties.name] = f;
  //             // console.log()
  //             // console.log('aoi_hash - Lvl 1', f);
  //                 if (!province_hash[f.properties.mine])
  //                 {
  //                   // console.log()
  //                     province_hash[f.properties.mine] = 1
  //                     mines.push(f.properties.mine)
  //                     // console.log('Provinces', this.provinces)
  //                 }
  //     })
  //     // console.log('Mine', mines)

  //     // Specific AOI
  //     for (let i = 0; i < provinces.length; i++){
  //       //Create name of object
  //           let newObj = {}
  //           newObj.name = provinces[i]
  //               //Loop current list of array
  //               let filterArr = this.aois.filter(el => {
  //               //Check condition to return
  //               // console.log('Inner Filter',el)
  //                   if(el.properties.province === provinces[i]){
  //                       el.name = el.properties.name
  //                       return el
  //                     }  
  //               })
  //           newObj.menu = filterArr
  //           specificContainer.menu.push(newObj)
  //       // console.log(holder.menu[currentCount])
  //           // console.log(holder.menu.length)
  //     }
  //     // console.log(JSON.parse(JSON.stringify(specificContainer)))

  //     this.specificNav = []
  //     this.specificNav.push(specificContainer)


  //     //Mine Nav
  //     for (let i = 0; i < mines.length; i++){
  //       //Create name of object
  //           let newObj = {}
  //           newObj.name = mines[i]
  //           // newObj.menu = []

  //           mineNav.menu.push(newObj)
  //     }
  //     // console.log(mineNav)

  //     // All Provinces
  //     for (let i = 0; i < provinces.length; i++){
  //       //Create name of object
  //           let newObj = {}
  //           // newObj.menu = []
  //           newObj.name = provinces[i]
  //           provNav.menu.push(newObj)
  //     }
  //     // console.log(provNav)

  //     // this.fileMenuItems.push(provNav,mineNav,specificContainer)
      

  // }
  }
  
}
</script>
