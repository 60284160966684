import axios from 'axios'
// import { RateLimit } from '@/ultis/RateLimit'

// const rateLimit = new RateLimit(2, 500)

//const END_POINT = 'https://imoa.eofactory.ai:3443/api/v1/layers'
const END_POINT = 'https://cache.skymapdataservice.com/proxy.php'

// const API_KEY = window.config.PLANET_API_KEY
const API_KEY = 'H50jsyR7JpDwj7sVoU86hkoaN1Pr6osF2xyJWaKTNnqK27jx5Oft1NLRsHB7DCgt'

// console.log('EndPoint V1: ', proccess.env.VUE_APP_BACKEND_END_POINT);
// console.log('EndPoint V1: ', dot.env.VUE_APP_BACKEND_END_POINT);

// console.log('EndPoint V2: ', window.config.VUE_APP_BACKEND_END_POINT);

// const END_POINT = proccess.env.VUE_APP_BACKEND_END_POINT
// const API_KEY = window.config.PLANET_API_KEY
// const API_KEY = proccess.env.VUE_APP_API_KEY


class EOfactoryApi {

  constructor() {
    this.headers = {
        'X-Authorization': API_KEY,
        'Accept': 'application/json'
    }
  }

  async getDetails(aoi,from_date,to_date){
    const data = await axios.get(END_POINT, {
        headers: this.headers,
        params:{
            from_date: from_date,
            to_date: to_date,
            aoi_ids: aoi
        }})
        .then((res)=> {
            // console.log('All Layers: ',res.data)
           return res.data
        })
        .catch((error) => {
          console.log(error);
            return error
            // console.error(error)
        })
        
        return data
    }
//   async performStats(
//     geometry,
//     startDate,
//     endDate,
//     cloudCover = 20,
//     sunElevation = [0, 90],
//     interval
//   ) {
//     const data = await this.createRequestData(
//       geometry,
//       startDate,
//       endDate,
//       cloudCover,
//       sunElevation,
//       interval
//     )

//     const res = await this.request({
//       method: 'POST',
//       url: '/stats',
//       data: data,
//     })

//     return res.data
//   }

  /**
   * @public
   * @param {Object} geometry geojson geometry
   * @param {String} startDate ISO Date String
   * @param {String} endDate ISO Date String
   * @param {Number} cloudCover Cloud cover percentage [0..100]
   */
//   async quickSearch(
//     geometry,
//     startDate,
//     endDate,
//     cloudCover = 20,
//     sunElevation = [0, 90]
//   ) {
//     const data = await this.createRequestData(
//       geometry,
//       startDate,
//       endDate,
//       cloudCover,
//       sunElevation
//     )

//     const res = await this.request({
//       method: 'POST',
//       url: '/quick-search',
//       data: data,
//     })

//     return res.data.features.map((feature) => {
//       feature.properties.tile_url = `https://tiles{s}.planet.com/data/v1/${feature.properties.item_type}/${feature.id}/{z}/{x}/{y}.png?api_key=${API_KEY}`
//       feature.properties.subdomains = [0, 1, 2, 3]

//       return feature
//     })
//   }

//   async createRequestData(
//     geometry,
//     startDate,
//     endDate,
//     cloudCover = 20,
//     sunElevation = [0, 90],
//     interval = 'day'
//   ) {
//     if (!this.itemTypes) {
//       this.itemTypes = await this.getItemTypes()
//     }

//     const geomFilter = await this.getGeomFilter(geometry)

//     const data = {
//       //interval: interval,
//       item_types: this.itemTypes.map((type) => type.id),
//       filter: {
//         type: 'AndFilter',
//         config: [
//           geomFilter,
//           {
//             type: 'DateRangeFilter',
//             field_name: 'acquired',
//             config: {
//               gte: startDate,
//               lte: endDate,
//             },
//           },
//           {
//             type: 'RangeFilter',
//             field_name: 'cloud_cover',
//             config: {
//               lte: cloudCover / 100,
//             },
//           },
//           {
//             type: 'RangeFilter',
//             field_name: 'sun_elevation',
//             config: {
//               gte: sunElevation[0],
//               lte: sunElevation[1],
//             },
//           },
//         ],
//       },
//     }
//     return data
//   }

//   async getGeomFilter(geom) {
//     if (geom.length > 1) {
//       return {
//         type: 'OrFilter',
//         config: geom.map((g) => {
//           return {
//             type: 'GeometryFilter',
//             field_name: 'geometry',
//             config: g,
//           }
//         }),
//       }
//     } else if (geom.length === 1) {
//       return {
//         type: 'GeometryFilter',
//         field_name: 'geometry',
//         config: geom[0],
//       }
//     }
//   }

  // async getAssets(image) {
  //   const res = await this.request({
  //     method: 'GET',
  //     url: image._links.assets.replace('https://api.planet.com/data/v1', ''),
  //   })

  //   return res.data
  // }

  // async activateAsset(asset) {
  //   const res = await this.request({
  //     method: 'POST',
  //     url: asset._links.activate.replace('https://api.planet.com/data/v1', ''),
  //   })

  //   return res.data
  // }

  // async getBaseMapMosaics() {
  //   const res = await this.request({
  //     method: 'GET',
  //     url: '/basemaps/v1/mosaics',
  //     params: {
  //       public: true,
  //     },
  //   })

  //   return res.data.mosaics
  // }
}

const overviewAPI = new EOfactoryApi()

// overviewAPI.getDetails();

export default overviewAPI
