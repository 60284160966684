<template >
  <v-menu
    ref="menu"
    class=""
    :close-on-content-click="true"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }" class="d-inline">
      <v-text-field
        style="border-bottom: 2px #893FF2 solid; width: 90px; margin: 0px 5px; display: inline-table;"
        class="month-picker text-center"
        dense
        hide-details
        v-model="dateTitle"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="rules"
        :disabled="disabled"
      ></v-text-field>
    </template>
      <!-- Item: {{dateModel}} -->
    <v-date-picker
      color="purple darken-4 d-inline"
      class=""
      :min="min"
      :max="max"
      type="month"
      v-model="date"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    localMonth: { type: String, required: true},
    // value: {type: String, default: undefined},
    min: {type: String, default: '2021-12'},
    max: {type: String, default: '2022-12'},
    label: {type: String, default: undefined},
    rules: {type: Array, default: undefined},
    disabled: {type: Boolean, default: false}
  },
  watch: {
    localMonth (val) {
      // console.log('localMonth: ', this.localMonth)
      // console.log('dateTitle: ', this.dateTitle)
      // console.log('date: ', this.date)
      // console.log('val: ', val)
      this.date = this.localMonth
      // this.dateTitle = this.fullMonthLabel[parseInt(val.substring(7,5)) - 1];
      // console.log
      // this.dateTitle = this.localMonth;
    }
  },
  computed: {
    date: {
      get () {
        return this.dateModel
      },
      set (val) {
        this.dateModel = val
        this.dateTitle = this.fullMonthLabel[parseInt(val.substring(7,5)) - 1];
        // console.log(dateTitle);

        this.$emit('input', this.date)
      }
    }
  },
  data: () => ({
    // dateMenu: false,
    dateModel: undefined,
    fullMonthLabel:  ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    dateTitle: ''
  }),
  created () {
    this.dateModel = this.localMonth
    // this.dateTitle = this.
  },
  mounted() {
    this.dateTitle = this.fullMonthLabel[parseInt(this.localMonth.substring(7,5)) - 1];
  }
}
</script>
<style>
/deep/
.month-picker .v-input__slot {
  min-height: 0 !important;
}
/deep/
.month-picker .v-input__append-inner {
  margin-top: 0 !important;
}
/deep/
.month-picker {
  border-bottom: 2px #893FF2 solid !important;
}
</style>
